import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  margin: 0px auto;

  @media only screen and (min-width: 1024px) {
    width: 100%;
  }

  @media only screen and (min-width: 1280px) {
    width: 90%;
  }

  @media only screen and (min-width: 1440px) {
    width: 100%;
  }

  h3 {
    width: 100%;
    text-align: center;
    font-size: 26px;
    color: #999;
    font-weight: bold;
  }

  .loading {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 14px;
  }

  .alert {
    background: #ff4d4d;
    padding: 6px;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    margin-top: 12px;
  }
`;

export const Schedule = styled.div`
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 8px;

  .block {
    border-radius: 4px;
    padding: 4px 6px;
    text-align: center;
    color: #fff;
  }

  .ongoing,
  .scheduled {
    background: #ff8c1a;
  }

  .canceled {
    background: #999;
  }

  .paid {
    background: #009933;
  }
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;

  .btn-hours {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .orange:hover {
    background: #ff8c1a;
    color: #ffffff;
  }

  .green:hover {
    background: #009933;
    color: #ffffff;
  }

  .red:hover {
    background: #ff4d4d;
    color: #ffffff;
  }

  > .active-orange {
    background: #ff8c1a;
    color: #ffffff;
  }

  > .active-green {
    background: #009933;
    color: #ffffff;
  }

  > .active-red {
    background: #ff4d4d;
    color: #ffffff;
  }

  button {
    background: #ffffff;
    color: #999;
    border-radius: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border: none;
    width: 100%;
    font-size: 13px;

    @media only screen and (min-width: 1280px) {
      font-size: 15px;
    }

    @media only screen and (min-width: 1480px) {
      font-size: 15px;
    }

    .line-orange {
      display: block;
      width: 30%;
      height: 3px;
      background: #ff8c1a;
      margin: 0px auto;
    }

    .line-green {
      display: block;
      width: 30%;
      height: 3px;
      background: #009933;
      margin: 0px auto;
    }

    .line-red {
      display: block;
      width: 30%;
      height: 3px;
      background: #ff4d4d;
      margin: 0px auto;
    }

    &:disabled {
      background: #eee;
      color: #ccc;
      cursor: no-drop;

      &:hover {
        background: #eee;
        color: #ccc;
      }
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 4px;

  .grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-bottom: 12px;

    h4 {
      margin: 0;
    }

    .legend-hour {
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 4px;
      padding-left: 8px;
      padding-right: 8px;

      div {
        display: flex;
        font-size: 14px;
        align-items: center;

        & + div {
          margin-left: 5px;
        }
      }
    }

    @media only screen and (min-width: 1280px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media only screen and (min-width: 1480px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  h4 {
    font-size: 24px;
    color: #999;
    font-weight: bold;
  }

  .container-action {
    display: flex;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;

  .ant-result-icon {
    svg {
      font-size: 48px;
    }
  }

  .ant-result-title {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    color: #505050;
  }

  .ant-result-subtitle {
    padding-top: 6px;
    font-size: 18px;
    line-height: 18px;
  }

  .ant-result-content {
    background: #fff;
    padding: 0;
    margin: 6px 0;
    text-align: center;
    color: #505050;

    h3 {
      font-size: 22px;
      color: #505050;
    }

    p {
      margin: 0;
      color: #505050;
    }

    .icone {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 28px;
        color: #5f7b51;
      }
    }
  }
`;
