const dictionary = {
  general: {
    logo: 'Portal do Cliente',
    abbreviationLogo: 'SD',
    copy: 'Copiar',
    notification: {
      error: 'Não foi possível carregar os dados.',
      pageNotFound: 'Página não encontrada',
    },
    mycart: {
      service: 'Serviço',
      limitUsed: 'Limite de Uso',
      courtesy: 'Cortesia',
      months: 'meses',
      planSelected: 'Plano selecionado',
      addMoreService: 'Deseja adicionar mais um serviço?',
      plan: 'Plano',
      signature: 'Assinatura',
      discount: 'Desconto',
      valueMonth: 'Valor Mensal',
      extra: 'Acréscimo',
    },
    codeSend: 'Código enviado por',
    mySignatures: 'Minha Assinaturas',
    myHistorical: 'Meu histórico',
    loading: 'Carregando',
    next: 'Próximo',
    previous: 'Anterior',
    cardCredit: 'Cartão de Crédito',
    update: 'Atualizar',
    back: 'Voltar',
    enter: 'Entrar',
    myPlan: 'Meu Plano',
    register: 'Cadastrar',
    proceed: 'Prosseguir',
    signatureComplete: 'Assinatura Completa!',
    charactersMin: 'caracteres no mínimo',
    charactersMax: 'caracteres no máximo',
    removed: 'Remover',
    add: 'Adicionar',
    yes: 'Sim',
    no: 'Não',
    scheduled: 'Agendar',
    viewMySignatures: 'Ver minhas assinaturas',
    excellentHappyDay: 'Parabéns pela excelente escolha',
    acceptTerm: 'Aceito e concordo com os termos',
    unitSelected: 'Unidade Escolhida',
    payment: {
      success: 'Compra efetuada com sucesso',
    },
    form: {
      charactersFour: '4 caracteres no mínimo',
      permissionCharactersFour: 'somente é permitido 4 caracteres.',
      informationValid: 'A informação está sendo validada',
      errorCode: 'O código obrigatório',
      insertCodeSend: 'Por favor! Insira o seu código enviado.',
      code: 'Código',
    },
  },
  messages: {
    success: 'Atualizado com sucesso.',
  },
  pageHeader: {
    search: 'Buscar',
  },
  navigation: {
    signatures: 'Minha Assinaturas',
    purchase: 'Adquirir',
    plans: 'Meu Plano',
    buynow: 'Comprar Agora',
    schedules: 'Agendar',
    myunit: 'Minha Unidade',
    profile: 'Perfil',
    exit: 'Sair',
  },
  table: {
    of: 'de',
    items: 'itens',
    perPage: ' por página',
    columnSearch: {
      search: 'Buscar',
      reset: 'Limpar',
    },
  },
  pages: {
    plans: {
      sign: 'Assinar Plano',
      win: 'Ganhe',
      changePlans: 'Escolha o plano',
      per: 'Por',
      buttons: {
        choose: 'ESCOLHO ESSE!',
        planSelected: 'PLANO SELECIONADO',
        youSave: 'Você economiza:',
      },
    },
    signRegister: {
      validationUser: 'Validação de Usuário',
      sendCodeEmail: 'Enviar código via email',
      sendCodeSms: 'Enviar código via sms',
      resetPassword: 'Resetar senha',
    },
    profile: {
      forms: {
        fillCity: 'preenche sua cidade',
        fillZipCode: 'preencha seu CEP',
        fillAddress: 'preencha seu Endereço',
        fillEmail: 'preencha seu Email',
        fillCountry: 'preencha seu País',
        fillState: 'preencha seu Estado',
      },
    },
    signatures: {
      lessDetails: 'Mostrar menos detalhes',
      moreDetails: 'Mostrar mais detalhes',
      notification: {
        error: 'Não foi possível fazer o pagamento',
        success: 'Pagamento realizado',
        gateway: {
          card_number: 'Número cartão de crédito',
          card_expiration: 'Validade do cartão',
          card_cvv: 'CVC',
        },
      },
      status: {
        success: 'Ativo',
        canceled: 'Cancelado',
        expired: 'Expirado',
        delayed: 'Atrasado',
        enabled: 'Disponível',
        active: 'Ativo',
        loser: 'Expirado',
        pending: 'Processando',
        processing: 'Processando',
        renew: 'Renovado',
        canceled_by_not_renew: 'Cancelado por não renovação',
        canceled_by_delayed: 'Cancelado por atraso',
      },
      detailsPayment: 'Detalhe de pagamento',
      dueDate: 'Vencimento',
      start: 'Início em',
      paidOut: 'Pago em',
      value: 'Valor',
      statusTxt: 'Status',
      services: {
        enabled: 'aberto',
        used: 'utilizado',
        canceled: 'cancelado',
        expired: 'expirado',
        delayed: 'atrasado',
        blocked: 'bloqueado',
        loser: 'perdido', // fracassado a tradução
        success: 'sucesso',
      },
      revenue: {
        paid: 'pago',
        used: 'utilizado',
        scheduled: 'agendado',
        canceled: 'cancelado',
        delayed: 'atrasado',
        blocked: 'bloqueado',
        success: 'sucesso',
        processing: 'Aguardando',
      },
      dateProceeding: 'Data do procedimento',
      proceedingPayment: 'Procedimentos realizados',
      none: 'Nenhum consumo',
    },
    schedules: {
      ongoing: 'Agendado', // em progresso
      scheduled: 'Agendado',
      mySchedule: 'Meus Agendamentos',
      canceled: 'Cancelado',
      paid: 'Finalizado',
      unit: 'Unidade',
    },
    login: {
      letStart: 'Vamos Começar',
      hello: 'Olá',
      goodYouBack: 'Que bom você de volta',
    },
    myUnit: {
      title: 'Minha Unidade',
    },
  },
  footer: 'Segue a gente nas redes socias',
  home: {
    title: 'Página inicial',
  },
};

export default {
  locale: 'pt-BR',
  label: 'Português (Brasil)',
  country: 'BR',
  currency: 'BRL',
  dictionary,
};
