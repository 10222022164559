import React, { useState } from 'react';

import ReactHtmlParser from 'react-html-parser';
import { Slide } from 'react-awesome-reveal';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { useAuth } from 'hooks/auth';
import FormGrid from '../include/form';
import { description } from '../include/geral';

import { Container, Header, Content, Footer, Paragraph } from '../styles';

interface IDesktop {
  loading: boolean;
  form: any;
  handleLogin: any;
  nameUser: string;
  handleSendCode: any;
  handleIsPasswordReset: any;
  isPasswordReset: string;
  handleLoginResetPassword: any;
  loadingReset: boolean;
}

const Mobile: React.FC<IDesktop> = ({
  loading,
  form,
  handleLogin,
  nameUser,
  handleSendCode,
  handleIsPasswordReset,
  isPasswordReset,
  handleLoginResetPassword,
  loadingReset,
}) => {
  const { isPassword, handleIsPassword } = useAuth();
  const [active, setActive] = useState(false);

  return (
    <Container>
      <Header>
        <Content>
          <div className="container-header">
            <div className="logo-mobile">
              <img src="/static/images/logo.svg" alt="Grupo SD" />
            </div>
          </div>
          <div className="bg-woman">
            <Slide>
              <img src="/static/images/bg-woman.png" alt="Grupo SD" />
            </Slide>
          </div>
        </Content>
        <div className="description-welcome-mobile">
          <h4>
            Seja bem-vinda, <strong>SD LOVER!</strong>
          </h4>
        </div>
      </Header>
      <Footer>
        <div className="description-mobile">
          <button type="button" onClick={() => setActive(!active)}>
            Descubra as vantagens aqui{' '}
            {active ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
          </button>

          <Paragraph active={active}>
            {ReactHtmlParser(description())}
          </Paragraph>
        </div>

        <div className="form-mobile">
          <FormGrid
            loading={loading}
            isPassword={isPassword}
            handleLogin={handleLogin}
            form={form}
            handleIsPassword={handleIsPassword}
            nameUser={nameUser}
            isPasswordReset={isPasswordReset}
            handleSendCode={handleSendCode}
            handleIsPasswordReset={handleIsPasswordReset}
            handleLoginResetPassword={handleLoginResetPassword}
            loadingReset={loadingReset}
          />
        </div>

        <div className="bg-bubble" />
      </Footer>
    </Container>
  );
};

export default Mobile;
