import React, { ButtonHTMLAttributes } from 'react';

import SpinLoading from 'components/Loading/spin';

import { ButtonCustom } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  color?: 'default' | 'primary' | 'secondary' | 'warning' | 'danger';
  size?: 'large' | 'small' | 'default';
};

const CustomButton: React.FC<ButtonProps> = ({
  children,
  color = 'default',
  size = 'large',
  loading = false,
  ...rest
}) => {
  return (
    <ButtonCustom {...rest} color={color} size={size}>
      {loading ? <SpinLoading /> : children}
    </ButtonCustom>
  );
};

export default CustomButton;
