import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { useHistory } from 'react-router-dom';

import Loading from 'components/Loading';
import SelectUnit from 'components/SelectUnit';

import { useAuth } from 'hooks/auth';
import { useFields } from 'hooks/fields';

import { stringNumber } from 'utils/mask';
import ProfileForm from 'pages/Profiles/form';
import { Background, Grid, TextTop } from './styled';

const Home: React.FC = () => {
  const history = useHistory();
  const { userUnit, ifExistsAddress, user, handleUpdateAddress } = useAuth();
  const { fieldsProfile, handleZipCode } = useFields();
  const [loading, setLoading] = useState(false);

  if (userUnit !== 'null' && ifExistsAddress) {
    history.push('/buy');
  }

  const handleZipCodeChange = async (event: any) => {
    const zipcode = stringNumber(event.target.value);

    if (zipcode.length < 8) {
      return;
    }
    handleZipCode(zipcode);
  };

  const handleSubmit = async (values: any) => {
    const { address } = values;

    const userUpdate = {
      hasAllergies: user.hasAllergies,
      isBreastfeeding: user.isBreastfeeding,
      isPregnant: user.isPregnant,
      name: user.name,
      phone: user.phone,
      birthdate: values.user.birthdate,
    };

    setLoading(true);
    await handleUpdateAddress({ address, user: userUpdate });
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Home | Portal do Cliente</title>
      </Helmet>

      <Background>
        <Grid>
          <Loading loading={loading}>
            <TextTop>
              <h3>Estamos quase lá!</h3>
              {userUnit === 'null' ? (
                <p>
                  Conta pra gente qual a sua{' '}
                  <strong>Unidade SD favorita.</strong>
                </p>
              ) : (
                <p>
                  Preencha o dados do seu <strong>endereço.</strong>
                </p>
              )}
            </TextTop>
            {userUnit === 'null' ? (
              <SelectUnit />
            ) : (
              <ProfileForm
                fields={fieldsProfile}
                handleZipCode={handleZipCodeChange}
                onFinish={handleSubmit}
                loading={loading}
                exludeUser
                columns={2}
              />
            )}
          </Loading>
        </Grid>
      </Background>
    </>
  );
};

export default Home;
