import { createGlobalStyle } from 'styled-components';

import 'antd/dist/antd.css';

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

  * {
    margin: 0;
    padding:0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  .ant-switch-checked {
    background-color: #667862;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0;
    white-space: nowrap;
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    width: auto;
    background: none;
    margin-right: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-form-item-has-feedback .ant-form-item-explain-validating {
    color: #999;
  }

  .ant-form-item-explain-validating {
    color: #ff4d4d;
  }

  .site-layout-background{
    padding: 26px;
  }

  ::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,0.2);
  }

  a {
    text-decoration: none;
    color: #666666;
  }
  p {
    strong {
      font-weight: 800;
    }
  }
  strong {
    font-weight: bold;
  }
  html {
    height: 100%;
  }
  body {
    background: #e6e1de;
    color: #666666;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    overflow-x:hidden;
  }
  body, input, button {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
  }
  h1, h2, h3 h4, h5, h6 {
    font-weight: bold;
    color: #666666;
  }
  button {
    cursor: pointer;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
  }
  select option {
    font-size: 16px;
  }
  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: grey;
    font-size: 13px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;

    .table-icon {
      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;
      }
    }
  }
  thead {
    font-weight: bold;
    color: #BFB6BD;
    font-size: 14px;
  }
  thead tr td {
    padding: 10px;
  }
  tbody tr td {
    padding: 10px;
    border-top: 1px solid #eee;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .ant-layout {
    background: transparent;
  }

  .react-multiple-carousel__arrow--left {
    z-index: 9;
  }

  .react-multiple-carousel__arrow--right {
    z-index: 9;
  }

  .link-whatsapp {
    background: #4AC385;
    color: #fff;
    padding: 4px 6px;
    border-radius: 4px;
  }

  .link-phone {
    background: #0A65AC;
    color: #fff;
    padding: 4px 6px;
    border-radius: 4px;
  }
`;
