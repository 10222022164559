import { PropsWithChildren } from 'react';

import { InputSelect } from './styled';

const SelectCustom: PropsWithChildren<any> = ({
  placeholder,
  ...rest
}: {
  placeholder: string;
}) => <InputSelect placeholder={placeholder} {...rest} />;

export default SelectCustom;
