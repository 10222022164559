import React from 'react';

import { Form } from 'antd';

import { t } from 'ab18n';

import Input from 'components/Input';
import ButtonCustom from 'components/Button/custom';

import { GridForm } from '../styles';

interface IForm {
  isPassword: boolean;
  isPasswordReset: string;
  form: any;
  handleLogin: any;
  loading: boolean;
  handleIsPassword: any;
  handleSendCode: any;
  nameUser: string;
  handleIsPasswordReset?: any;
  handleLoginResetPassword: any;
  loadingReset: boolean;
}

const FormGrid: React.FC<IForm> = ({
  isPassword,
  isPasswordReset,
  loading,
  handleLogin,
  form,
  handleIsPassword,
  handleSendCode,
  nameUser,
  handleIsPasswordReset,
  handleLoginResetPassword,
  loadingReset,
}) => {
  return (
    <GridForm>
      {isPasswordReset ? (
        <>
          {isPasswordReset === 'emailOrSms' && (
            <>
              <div className="description login-password">
                <p>
                  <strong>{t('pages.signRegister.validationUser')}</strong>
                </p>
              </div>

              <div className="grid-send">
                <ButtonCustom
                  onClick={() => handleSendCode('email')}
                  className="btn-send-email"
                  disabled={loadingReset}
                >
                  {t('pages.signRegister.sendCodeEmail')}
                </ButtonCustom>

                <ButtonCustom
                  onClick={() => handleSendCode('sms')}
                  className="btn-send-sms"
                  disabled={loadingReset}
                >
                  {t('pages.signRegister.sendCodeSms')}
                </ButtonCustom>
              </div>

              <ButtonCustom
                type="button"
                color="danger"
                onClick={() => handleIsPasswordReset('')}
              >
                {t('general.back')}
              </ButtonCustom>
            </>
          )}

          {isPasswordReset === 'code' && (
            <>
              <div className="description login-password">
                <p>
                  <strong>{t('pages.signRegister.resetPassword')}</strong>
                </p>
              </div>
              <Form
                form={form}
                name="login"
                layout="vertical"
                onFinish={handleLoginResetPassword}
              >
                <Form.Item
                  name="code"
                  rules={[
                    { required: true, message: 'Senha é obrigatória' },
                    { min: 4, message: `4 ${t('general.charactersMin')}` },
                  ]}
                >
                  <Input type="text" placeholder="Insira o código" autoFocus />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: 'Senha é obrigatória' },
                    { min: 6, message: `6 ${t('general.charactersMin')}` },
                  ]}
                >
                  <Input type="password" placeholder="Nova Senha" autoFocus />
                </Form.Item>
                <Form.Item
                  name="password_confirmation"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, confirme sua senha!',
                    },
                    { min: 6, message: '' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'As duas senhas que você digitou não correspondem!',
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Confirma a senha"
                    autoFocus
                  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  <ButtonCustom type="submit" loading={loadingReset}>
                    {t('general.enter')}
                  </ButtonCustom>
                  <ButtonCustom
                    type="button"
                    color="danger"
                    onClick={() => handleIsPasswordReset('emailOrSms')}
                  >
                    {t('general.back')}
                  </ButtonCustom>
                </Form.Item>
              </Form>
            </>
          )}
        </>
      ) : (
        <>
          {isPassword ? (
            <div className="description login-password">
              <p>
                <strong>
                  {t('pages.login.hello')}, {nameUser}!
                </strong>
              </p>
              <p>{t('pages.login.goodYouBack')}</p>
              <p>Insira a senha para ter acesso a sua conta.</p>
            </div>
          ) : (
            <div className="description login-fiscalnumber">
              <p className="font-large">
                <strong>{t('pages.login.letStart')}!</strong>
              </p>
              <p>
                Insira o seu <strong>CPF</strong> para verificarmos se você já é{' '}
                <strong>Cliente SD.</strong>
              </p>
            </div>
          )}

          <Form
            form={form}
            name="login"
            layout="vertical"
            onFinish={handleLogin}
          >
            {!isPassword ? (
              <Form.Item
                name="identifier"
                hasFeedback={loading}
                validateStatus="validating"
                help={loading ? 'A informação está sendo validada...' : null}
                rules={[
                  { required: true, message: 'CPF é obrigatório' },
                  { min: 14, message: 'CPF inválido' },
                ]}
              >
                <Input
                  id="validating"
                  className="identifier"
                  placeholder="CPF"
                  mask="999.999.999-99"
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Senha é obrigatória' },
                  { min: 6, message: '6 caracteres no mínimo' },
                ]}
              >
                <Input
                  type="password"
                  className="password"
                  placeholder="Senha"
                  autoComplete="new-password"
                  autoFocus
                />
              </Form.Item>
            )}
            <Form.Item shouldUpdate>
              <div className="container-password">
                {isPassword ? (
                  <>
                    <ButtonCustom type="submit" loading={loading}>
                      Entrar
                    </ButtonCustom>
                    <ButtonCustom
                      type="button"
                      color="danger"
                      onClick={() => handleIsPassword(false)}
                    >
                      Voltar
                    </ButtonCustom>
                    <button
                      type="button"
                      className="btn-reset"
                      onClick={() => handleIsPasswordReset('emailOrSms')}
                    >
                      Esqueci minha senha
                    </button>
                  </>
                ) : (
                  <ButtonCustom type="submit" id="btn-submit" loading={loading}>
                    Verificar
                  </ButtonCustom>
                )}
              </div>
            </Form.Item>
          </Form>
        </>
      )}
    </GridForm>
  );
};

export default FormGrid;
