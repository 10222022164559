import React from 'react';

import { MdBrightness1 } from 'react-icons/md';

import { Footer } from './styles';

const FooterCell: React.FC = () => {
  return (
    <Footer>
      <div>
        <MdBrightness1 color="#ff4d4d" /> Loja Fechada
      </div>
    </Footer>
  );
};

export default FooterCell;
