import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { Zoom } from 'react-awesome-reveal';

import { useSelector, useDispatch } from 'react-redux';

import { Steps } from 'antd';
import { SolutionOutlined, CreditCardOutlined } from '@ant-design/icons';

import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdBrightness1,
} from 'react-icons/md';

import { gatewayLoadRequest } from 'store/modules/units/action';

import {
  subscriptionConfirmationSuccess,
  contractLoadRequest,
} from 'store/modules/subscriptions/action';
import { subscriptionsUseSelector } from 'store/modules/subscriptions/types';

import { plansUseSelector } from 'store/modules/plans/types';

import { useAuth } from 'hooks/auth';

import Address from './steps/address';
import Payment from './steps/payment';

import { SignContent, ContainerStep, Container } from './styled';

const { Step } = Steps;

const StepsPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedPlan, validateTerm } = useSelector(plansUseSelector);
  const { receipt, confirmation } = useSelector(subscriptionsUseSelector);
  const { userMe } = useAuth();

  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: 'Seu Endereço',
      content: <Address />,
      active: !receipt ? !!userMe.address : false,
      icon: <SolutionOutlined />,
    },
    {
      title: isMobile ? 'Dados do cartão' : 'Informe os dados do seu cartão',
      content: <Payment />,
      active: !receipt && confirmation ? validateTerm : false,
      icon: <CreditCardOutlined />,
    },
  ];

  const next = () => {
    const currentNext = current + 1;
    history.push('/buy?page=payment');
    setCurrent(currentNext);
  };

  const prev = () => {
    const currentPrev = current - 1;
    history.push('/buy?page=address');
    setCurrent(currentPrev);
  };

  const handleStep = (event: number) => {
    setCurrent(event);

    if (event === 2) {
      dispatch(subscriptionConfirmationSuccess({ check: false }));
    }
  };

  // Busca o contrato e o gateway da unidade
  useEffect(() => {
    if (Object.keys(userMe).length) {
      dispatch(contractLoadRequest());
      dispatch(gatewayLoadRequest({ email: userMe.unit.email }));
    }
  }, [userMe]); // eslint-disable-line

  useEffect(() => {
    // validando Unidade
    if (Object.keys(userMe).length) {
      // Validação Endereço
      if (!userMe?.address && selectedPlan && !receipt) {
        history.push('/buy?page=address');
        setCurrent(0);
        return;
      }

      // Validação confirmação
      if (userMe?.address && selectedPlan && !receipt) {
        history.push('/buy?page=payment');
        setCurrent(1);
        return;
      }

      // Validação Conclusão
      if (current >= 1 && receipt) {
        history.push('/thankyou', { payment: true });
      }
    }
  }, [userMe, selectedPlan, validateTerm, confirmation, receipt]); //eslint-disable-line

  return (
    <>
      <Helmet>
        <title>Adquirir | Portal do Cliente</title>
      </Helmet>

      <Container>
        <Zoom>
          <SignContent>
            <div className="title-next-prev">
              {steps[current - 1] && steps[current - 1].active ? (
                <div className="prev">
                  <MdKeyboardArrowLeft
                    onClick={() => prev()}
                    className="rightstep"
                    color="rgb(102, 120, 98)"
                  />
                </div>
              ) : (
                <>
                  {steps[current - 1] && (
                    <div className="prev">
                      <MdKeyboardArrowLeft
                        className="rightstep"
                        color="#c2c2c2"
                      />
                    </div>
                  )}
                </>
              )}

              <h2 className="title-step">{steps[current].title}</h2>

              {userMe?.address && current === 0 ? (
                <div className="next">
                  <MdKeyboardArrowRight
                    onClick={() => next()}
                    className="rightstep"
                    color="rgb(102, 120, 98)"
                  />
                </div>
              ) : (
                <>
                  {steps[current + 1] && (
                    <div className="next">
                      <MdKeyboardArrowRight
                        className="rightstep"
                        color="#c2c2c2"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            <ContainerStep>{steps[current].content}</ContainerStep>

            {!isMobile && (
              <div className="footer-step">
                <Steps current={current} onChange={handleStep}>
                  {steps.map(item => (
                    <Step
                      key={item.title}
                      disabled={!item.active}
                      icon={<MdBrightness1 />}
                    />
                  ))}
                </Steps>
              </div>
            )}
          </SignContent>
        </Zoom>
      </Container>
    </>
  );
};

export default StepsPage;
