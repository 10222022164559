import React from 'react';
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
} from 'date-fns';
import { useCalendar } from 'hooks/calendar';
import CalendarCell from './CalendarCell';

import { Cells } from './styles';

const CalendarCells: React.FC = () => {
  const { weekStartsOn, currentMonth } = useCalendar();

  if (!currentMonth) return null;

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const dateStart = startOfWeek(monthStart, {
    weekStartsOn,
  });

  const dateEnd = endOfWeek(monthEnd, {
    weekStartsOn,
  });

  const dates = [];

  let date = dateStart;

  while (date <= dateEnd) {
    dates.push(<CalendarCell key={format(date, 'T')} date={date} />);

    date = addDays(date, 1);
  }

  return <Cells>{dates}</Cells>;
};

export default CalendarCells;
