import { ISubscriptions } from 'store/modules/subscriptions/types';

export const verifyStatusSubscriptions = (subscriptions: ISubscriptions[]) => {
  const verifyStatusSubscriptions = subscriptions.map(item => item.status);
  const status =
    verifyStatusSubscriptions.includes('success') ||
    verifyStatusSubscriptions.includes('active') ||
    verifyStatusSubscriptions.includes('delayed');

  return status;
};
