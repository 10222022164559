import { call, put, select } from 'redux-saga/effects';

import { t } from 'ab18n';
import valid from 'card-validator';
import axios from 'axios';

import api from 'services/api';
import message from 'components/Message';
import fingerPrint from 'utils/fingerprint';

import {
  subscriptionsLoadFailure,
  subscriptionsLoadSuccess,
  subscriptionsReceiptSuccess,
  contractLoadSuccess,
} from './action';

import { unitsUseSelector } from '../units/types';

export function* loadSubscriptions(): any {
  try {
    const response = yield call(api.get, 'subscriptions');

    yield put(subscriptionsLoadSuccess(response.data.data));
  } catch (err: any) {
    yield put(subscriptionsLoadFailure());
    message.responseErrors(err);
  }
}

export function* createSubscription({ payload }: any) {
  const { gateway } = yield select(unitsUseSelector);

  const { data } = payload;

  const { card }: any = valid.number(data.cardNumber);

  try {
    yield call(api.post, 'contracts/sign', {
      fingerprint: String(fingerPrint()),
      latitude: data.latitude,
      longitude: data.longitude,
    });

    const { data: resGateWay } = yield call(
      axios.post,
      gateway.paymentProfileUri,
      {
        holder_name: data.cardName,
        card_expiration: data.cardExpiry,
        card_number: data.cardNumber,
        card_cvv: data.cardCvc,
        payment_method_code: 'credit_card',
        payment_company_code: card.type,
      },
      {
        auth: { username: gateway.vindiPublicKey, password: '' },
      },
    );

    const { gateway_token } = resGateWay.payment_profile;
    yield call(api.post, 'subscriptions/create-vindi', {
      deviceFingerprintID: fingerPrint(),
      gatewayToken: gateway_token,
      planUser: data.planUser,
      customerIdentifier: data.customerIdentifier,
      isDigitalTerm: true,
    });
    message.success(`${t('general.payment.success')}`, 12);
    yield put(subscriptionsReceiptSuccess({ check: true }));
  } catch (err: any) {
    const error = err.response.data;

    if (error.message) {
      message.error(error.message, 12);
      yield put(subscriptionsReceiptSuccess({ check: false }));
      return;
    }

    const { errors } = error;

    const isArray = errors.length;

    if (isArray) {
      const error = errors[0];

      // Identificar erro na vindi
      if (error.parameter) {
        message.error(
          `${t(`pages.signatures.notification.gateway.${error.parameter}`)} ${
            error.message
          }`,
          12,
        );

        return;
      }

      if (error) {
        message.warning(error, 12);
      }
    } else {
      const { paymentError } = errors;

      if (paymentError) {
        message.error(`${paymentError[0]}`, 12);
      }
    }

    yield put(subscriptionsReceiptSuccess({ check: false }));
  }
}

export function* loadContracts(): any {
  try {
    const response = yield call(api.get, 'contracts/current');

    yield put(contractLoadSuccess(response.data.data));
  } catch (err: any) {
    console.log(err);
  }
}
