import styled, { css, keyframes } from 'styled-components';
import { isChrome } from 'react-device-detect';

interface IPropsNavigation {
  collapsed: boolean;
}

const customAnimationRight = keyframes`
  from {
    transform: translateX(-275px);
  }

  to {
    transform: translateX(0);
  }
`;

const customAnimationLeft = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-275px);
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const BgLeaf = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  right: -584px;
  z-index: -1;

  img {
    width: 30%;
  }

  @media only screen and (min-width: 1280px) {
    display: block;
    right: -505px;
    img {
      width: 40%;
    }
  }

  @media only screen and (min-width: 1480px) {
    display: block;
    right: -505px;
    img {
      width: 40%;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: space-between;
  height: 78px;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  background: #efefed;
  color: #667862;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  z-index: 99;

  .logo-mobile {
    display: block;
    position: absolute;
    width: 40px;
    left: 50%;
    margin-left: -20px;

    img {
      width: 40px;
      opacity: 0.7;
    }
  }

  @media only screen and (min-width: 1280px) {
    height: 84px;
  }

  @media only screen and (min-width: 1480px) {
    height: 84px;
  }

  .toggleandbread {
    display: flex;
    align-items: center;

    .logo {
      display: block;
      width: 160px;
      margin-right: 12px;

      @media only screen and (min-width: 1280px) {
        width: 216px;
      }

      @media only screen and (min-width: 1480px) {
        width: 216px;
      }

      img {
        width: 100%;
        opacity: 0.8;
      }
    }
  }
`;

export const GridTop = styled.div`
  display: flex;

  .profile {
    display: flex;
    align-items: center;

    button {
      border: 0;
      background: none;
      font-size: 15px;
    }

    div {
      display: none;
      text-align: right;
      margin-right: 6px;

      p {
        margin: 0px;
        color: #3c4639;
      }

      line-height: 18px;
    }

    @media only screen and (min-width: 481px) {
      div {
        display: block;
      }
    }

    @media only screen and (min-width: 1024px) {
      div {
        display: block;
      }
    }

    @media only screen and (min-width: 1280px) {
      div {
        display: block;
      }
    }
  }
`;

export const Navigation = styled.div<IPropsNavigation>`
  display: flex;
  position: fixed;
  width: 240px;
  height: 100%;
  flex-direction: column;
  background: #fff;
  z-index: 98;

  ${!isChrome
    ? css`
        ${(props: any) =>
          !props.collapsed
            ? css`
                animation: ${customAnimationRight} 0.2s ease-in;
                transform: translate3d(0, 0, 0);
              `
            : css`
                animation: ${customAnimationLeft} 0.2s ease-in-out;
                transform: translate3d(-275px, 0, 0);
              `}
      `
    : css`
        transition: 0.2s ease-out 0s;

        ${(props: any) =>
          props.collapsed
            ? css`
                margin-left: -275px;
              `
            : css`
                margin-left: 0px;
              `}
      `}

  @media only screen and (min-width: 1280px) {
    position: relative;
  }

  @media only screen and (min-width: 1480px) {
    position: relative;
  }

  .menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 99;
    width: 240px;
    height: 100%;
    background: #fff;
    padding-top: 90px;
    box-shadow: 0 8px 10px -2px rgba(0, 0, 0, 0.15);

    ul {
      width: 100%;
      padding-left: 14px;
      padding-right: 14px;
      list-style: none;
      font-size: 15px;
      font-weight: 600;

      li {
        display: flex;
        width: 100%;

        a {
          display: flex;
          width: 100%;
          padding: 8px 12px;
          border-radius: 6px;
          transition: background-color 0.2s;
          color: #a8aaa8;
          margin-top: 8px;
          color: #3e5f34;

          .icon {
            width: 28px;
          }

          &:hover {
            background: #b7c1b2;
          }
        }

        .active {
          background: #b7c1b2;
        }
      }
    }
  }

  .logout {
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 22px;

    button {
      border: 0;
      display: flex;
      width: 100%;
      padding: 8px 12px;
      border-radius: 6px;
      transition: background-color 0.2s;
      color: #3e5f34;
      background: transparent;
      font-size: 15px;
      font-weight: 600;

      .icon {
        width: 28px;
      }

      &:hover {
        background: #b7c1b2;
      }
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  height: auto;
  padding-top: 130px;
  padding-bottom: 60px;
  padding-left: 22px;
  padding-right: 22px;

  .container {
    display: block;
    width: 100%;
    margin: 0px auto;
    max-width: 1240px;
    padding-bottom: 40px;
  }
`;

export const ContainerLogin = styled.div`
  display: block;
  width: 100%;
`;

export const LayoutInitial = styled.div`
  height: 100vh;
  width: 100%;
  padding: 0;
  display: inline-flex;
  overflow-x: hidden;
  position: relative;
`;
