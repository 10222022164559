import React from 'react';
import { Form } from 'antd';

import { useAuth } from 'hooks/auth';
import { IIdentifier } from 'hooks/iTypes';

import Button from 'components/Button/custom';
import Input from 'components/Input';

import { GridForm } from './styles';

import { RegisterFormProps } from './ITypes';

const RegisterForm: React.FC<RegisterFormProps> = ({
  handleSubmit,
  handleChangeVerifyIsIdentify,
  history,
  loading,
}) => {
  const { identifier, handleIdentifier } = useAuth();

  const verifyIdentifier = Object.keys(identifier).length;

  return (
    <GridForm id="grid-register">
      <div className="grid-text">
        <h4>Que bom te encontrar!</h4>
        <p>
          Faltam poucos passos para você aproveitar todas as vantagens do SD
          Lover. Precisamos que insira algumas informações:
        </p>
      </div>
      <Form
        name="register"
        layout="vertical"
        fields={[
          { name: ['name'], value: identifier?.name },
          {
            name: ['identifier'],
            value: identifier?.identifier,
          },
          { name: ['phone'], value: identifier.phone },
          { name: ['email'], value: identifier?.email },
          { name: ['password'], value: identifier?.password },
          { name: ['passwordConfirmation'], value: identifier?.password },
        ]}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: 'Nome é obrigatório' }]}
        >
          <Input
            textTransform="capitalize"
            placeholder="Nome"
            color="white"
            disabled={!verifyIdentifier ? false : !identifier.newUser}
          />
        </Form.Item>
        <Form.Item
          name="identifier"
          hasFeedback
          rules={[
            { required: true, message: 'Por favor, insira o CPF' },
            { message: '' },
          ]}
        >
          <Input
            placeholder="CPF"
            mask="999.999.999-99"
            color="white"
            onChange={handleChangeVerifyIsIdentify}
            disabled={!verifyIdentifier ? false : !identifier.newUser}
          />
        </Form.Item>
        <div className="grid">
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'E-mail é obrigatório' }]}
          >
            <Input
              placeholder="E-mail"
              type="email"
              color="white"
              disabled={!verifyIdentifier ? false : !identifier.newUser}
            />
          </Form.Item>
          <Form.Item name="phone">
            {verifyIdentifier && !identifier.newUser ? (
              <Input placeholder="Celular" disabled color="white" />
            ) : (
              <Input
                color="white"
                placeholder="Celular"
                mask="(99) 99999-9999"
              />
            )}
          </Form.Item>
        </div>
        <div className="grid">
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Por favor insira sua senha!',
              },
              { min: 6, message: '6 caracteres no mínimo' },
            ]}
          >
            <Input placeholder="Senha" color="white" type="password" />
          </Form.Item>
          <Form.Item
            name="passwordConfirmation"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Por favor, confirme sua senha!',
              },
              { min: 6, message: '' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'As duas senhas que você digitou não correspondem!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input
              placeholder="Confirmação de senha"
              type="password"
              color="white"
            />
          </Form.Item>
        </div>
        <Form.Item
          hasFeedback={loading}
          help={loading ? 'A informação está sendo validada...' : null}
        >
          <div className="grid">
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                handleIdentifier({} as IIdentifier);
                history.push('/login');
              }}
            >
              Voltar
            </Button>
            <Button type="submit">
              {Object.keys(identifier).length ? (
                <>{identifier.newUser ? 'Entrar' : 'Avançar'}</>
              ) : (
                'Entrar'
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </GridForm>
  );
};

export default RegisterForm;
