import React from 'react';
import { Form } from 'antd';

import { DoubleLeftOutlined } from '@ant-design/icons';

import { t } from 'ab18n';

import Input from 'components/Input';
import Button from 'components/Button/custom';

import { useAuth } from 'hooks/auth';

import { GridForm } from './styles';

interface IValidationCode {
  handleValidationCode: any;
  loading: boolean;
}

const ValidationCode: React.FC<IValidationCode> = ({
  handleValidationCode,
  loading,
}) => {
  const { identifier, handleIdentifier } = useAuth();

  return (
    <GridForm>
      <h3>{t('general.form.insertCodeSend')}</h3>
      <Form name="validation" layout="vertical" onFinish={handleValidationCode}>
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              message: t('general.form.errorCode'),
            },
            { min: 4, message: t('general.form.charactersFour') },
            { max: 4, message: t('general.form.permissionCharactersFour') },
          ]}
        >
          <Input
            placeholder={t('general.form.code')}
            type="number"
            color="white"
          />
        </Form.Item>
        <Form.Item
          hasFeedback={loading}
          help={loading ? `${t('general.form.informationValid')}` : null}
        >
          <div className="grid">
            <Button
              type="button"
              color="secondary"
              onClick={() =>
                handleIdentifier({ ...identifier, validationCode: false })
              }
            >
              <DoubleLeftOutlined /> {t('general.back')}
            </Button>
            <Button type="submit">
              {loading ? `${t('general.loading')}...` : 'Entrar'}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </GridForm>
  );
};

export default ValidationCode;
