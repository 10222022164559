import styled, { css } from 'styled-components';

interface IContainer {
  loading: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  z-index: 999;

  ${props =>
    props.loading
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  div {
    display: block;
  }
`;
