import { Reducer } from 'redux';
import LOCALSTORAGEKEY from 'constants/localStorage';
import { PlanState, PlansTypes } from './types';

let planLocalStorage = localStorage.getItem('@AppAlphaSd:plan');

if (planLocalStorage === 'undefined') {
  planLocalStorage = '';
  localStorage.removeItem(LOCALSTORAGEKEY.PLAN);
}

const INITIAL_STATE: PlanState = {
  data: [],
  error: false,
  loading: false,
  selectedPlan: planLocalStorage ? JSON.parse(planLocalStorage) : '',
  validateTerm: false,
};

const reducer: Reducer<PlanState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlansTypes.LOAD_PLANS_REQUEST:
      return { ...state, loading: true };
    case PlansTypes.LOAD_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case PlansTypes.LOAD_PLANS_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    case PlansTypes.SELECT_PLANS_REQUEST:
      return {
        ...state,
        selectedPlan: action.payload.data,
      };
    case PlansTypes.VALIDATE_PLANS_REQUEST:
      return {
        ...state,
        validateTerm: action.payload.data.check,
      };
    case PlansTypes.CLEAR_SELECTED_PLANS_REQUEST:
      return {
        ...state,
        selectedPlan: '',
        validateTerm: false,
      };
    case PlansTypes.RESET_PLANS_REQUEST:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default reducer;
