import React from 'react';

import LoginLayout from './loginLayout';
import BaseLayout from './baseLayout';

import { useAuth } from '../../hooks/auth';

const Layout: React.FC = ({ children }) => {
  const { logged, userUnit, ifExistsAddress } = useAuth();

  if (logged && userUnit !== 'null' && ifExistsAddress) {
    return <BaseLayout>{children}</BaseLayout>;
  }

  return <LoginLayout>{children}</LoginLayout>;
};

export default Layout;
