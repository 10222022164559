import styled from 'styled-components';

export const TermCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  button {
    margin-top: 8px;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;

    button {
      margin-top: 0px;
    }
  }

  @media only screen and (min-width: 1280px) {
    flex-direction: row;

    button {
      margin-top: 0px;
    }
  }
`;

export const ContainerPayment = styled.div`
  display: block;
  margin-top: 0px;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    margin-top: 14px;
  }

  @media only screen and (min-width: 1280px) {
    margin-top: 14px;
  }

  .selectedPlan {
    display: block;
    align-items: center;
    justify-content: space-between;
    background: #eee;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 12px 22px;
    text-align: center;

    @media only screen and (min-width: 1024px) {
      display: flex;
      text-align: left;
    }

    @media only screen and (min-width: 1280px) {
      display: flex;
      text-align: left;
    }

    .unit {
      padding-bottom: 20px;

      @media only screen and (min-width: 1024px) {
        padding-bottom: 0px;
      }

      @media only screen and (min-width: 1280px) {
        padding-bottom: 0px;
      }
    }

    h3 {
      margin: 0;
      font-size: 18px;
      line-height: 18px;

      @media only screen and (min-width: 1024px) {
        font-size: 18px;
        line-height: 18px;
      }

      @media only screen and (min-width: 1280px) {
        font-size: 18px;
        line-height: 18px;
      }

      @media only screen and (min-width: 1440px) {
        font-size: 22px;
        line-height: 22px;
      }
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 14px;

      @media only screen and (min-width: 1024px) {
        font-size: 15px;
        line-height: 15px;
      }

      @media only screen and (min-width: 1280px) {
        font-size: 15px;
        line-height: 15px;
      }

      @media only screen and (min-width: 1440px) {
        font-size: 18px;
        line-height: 18px;
      }
    }

    .price {
      font-size: 32px;
      font-weight: bold;
      color: #667862;

      @media only screen and (min-width: 1024px) {
        font-size: 36px;
      }

      @media only screen and (min-width: 1280px) {
        font-size: 36px;
      }

      @media only screen and (min-width: 1440px) {
        font-size: 42px;
      }
    }
  }

  .grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
    }

    @media only screen and (min-width: 1280px) {
      flex-direction: row;
    }

    @media only screen and (min-width: 1440px) {
      flex-direction: row;
    }
  }

  .containerpayment {
    display: block;
    width: 100%;
    margin-right: 22px;
  }

  .cardpayment {
    border-radius: 8px;
    width: 100%;

    .gridtemplate_one {
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;
      width: 100%;

      .ant-form-item {
        display: block;
      }
    }

    .gridtemplate_two {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      width: 100%;

      .ant-form-item {
        display: block;
      }
    }

    button {
      width: 100%;
    }

    ul {
      list-style: none;
      padding: 0;

      li:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
`;

export const ContainerAddress = styled.div`
  display: block;
  width: 100%;
  margin: 12px auto;

  .grid-form-two {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;
  }

  .grid-form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;

    .grid-form-two {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 14px;
    }

    .grid-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 14px;
    }
  }

  @media only screen and (min-width: 1280px) {
    width: 100%;

    .grid-form-two {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 14px;
    }

    .grid-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 14px;
    }
  }

  @media only screen and (min-width: 1440px) {
    width: 80%;
  }
`;

export const ContainerConfirmation = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 14px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 1280px) {
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 1440px) {
    margin-bottom: 0px;
  }

  button {
    width: 100%;
    margin: 0px auto;

    @media only screen and (min-width: 1024px) {
      width: 50%;
    }

    @media only screen and (min-width: 1280px) {
      width: 50%;
    }

    @media only screen and (min-width: 1440px) {
      width: 50%;
    }

    h2 {
      color: #fff;
      margin: 0px;
    }
  }
`;

export const ContainerInfoPayment = styled.div`
  display: block;
  width: 100%;
  border-radius: 8px;
  background: #e1d9d7;
  padding: 16px;
  margin-bottom: 22px;
  color: #514d4c;

  .unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
    }

    @media only screen and (min-width: 1280px) {
      flex-direction: row;
    }

    @media only screen and (min-width: 1440px) {
      flex-direction: row;
    }

    .unit-info {
      font-size: 20px;
      line-height: 20px;
      text-align: center;

      @media only screen and (min-width: 1024px) {
        text-align: left;
      }

      @media only screen and (min-width: 1280px) {
        text-align: left;
      }

      @media only screen and (min-width: 1440px) {
        text-align: left;
      }

      p {
        font-size: 18px;
      }
    }

    .unit-price {
      display: block;
      text-align: center;
      color: #525949;

      @media only screen and (min-width: 1024px) {
        text-align: right;
      }

      @media only screen and (min-width: 1280px) {
        text-align: right;
      }

      @media only screen and (min-width: 1440px) {
        text-align: right;
      }

      .text-price {
        font-size: 28px;
        font-weight: bold;
        margin-top: -8px;
        margin-bottom: -6px;
      }

      small {
        font-weight: bold;
        font-size: 12px;
      }

      .sub-title {
        font-size: 12px;
      }
    }
  }

  .add-plan {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    margin-top: 12px;

    input {
      margin-right: 8px;
      width: 34px;
      height: 34px;

      @media only screen and (min-width: 1024px) {
        width: 22px;
        height: 22px;
      }

      @media only screen and (min-width: 1280px) {
        width: 22px;
        height: 22px;
      }

      @media only screen and (min-width: 1440px) {
        width: 22px;
        height: 22px;
      }
    }
  }

  .term {
    margin-top: 22px;

    button {
      background: none;
      border: none;
      font-size: 14px;
      color: #1c83e9;
    }
  }
`;
