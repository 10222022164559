import React, { useState, useEffect } from 'react';
import { Form, Skeleton, Select, Switch } from 'antd';

import Input from 'components/Input';
import ButtonContainer from 'components/Button/custom';
import SelectInput from 'components/Select';

import { Country, IStates } from 'utils/locales';

import { useAuth } from 'hooks/auth';

import { Container, Grid } from './styled';

const { Option } = Select;

interface IProfileForm {
  fields: any[];
  onFinish?: any;
  handleZipCode?: any;
  loading: boolean;
  exludeUser?: boolean;
  columns?: number;
}

const ProfileForm: React.FC<IProfileForm> = ({
  fields,
  onFinish = null,
  handleZipCode,
  loading,
  exludeUser = false,
  columns = 1,
}) => {
  const { userMe } = useAuth();

  const [states, setStates] = useState<IStates[]>([] as IStates[]);

  useEffect(() => {
    const getCountry: any = Country.find(
      (item): any => item.abbreviation === userMe.address?.country,
    );

    const state = getCountry ? getCountry.state : [];

    setStates(state);
  }, [userMe]);

  const handleChangeCountry = (e: any, options: any) => {
    setStates(options.states);
  };

  return (
    <Container>
      {userMe ? (
        <>
          {fields.length > 0 && (
            <Form
              name="customer_data"
              id="form-profile"
              fields={fields}
              onFinish={value => onFinish(value)}
              initialValues={{ remember: true }}
              style={{
                display: 'inline-block',
                marginBottom: '50px',
                width: '100%',
              }}
            >
              <div className="container-grid">
                {!exludeUser && (
                  <div className="grid">
                    <h2>Acesso</h2>
                    <Grid columns={columns}>
                      <Form.Item
                        name={['user', 'name']}
                        rules={[
                          {
                            required: true,
                            message: 'preenche seu nome.',
                          },
                        ]}
                      >
                        <Input placeholder="Nome" color="white" />
                      </Form.Item>
                      <Form.Item
                        name={['user', 'phone']}
                        rules={[
                          { required: true, message: 'preenche seu número.' },
                        ]}
                      >
                        <Input placeholder="Telefone" color="white" />
                      </Form.Item>
                      <Form.Item
                        name={['user', 'isWhatsapp']}
                        label="Este número é whatsapp?"
                        valuePropName="checked"
                      >
                        <Switch defaultChecked />
                      </Form.Item>
                      <Form.Item
                        name="identifier"
                        rules={[
                          { required: true, message: 'preenche seu CPF.' },
                        ]}
                      >
                        <Input placeholder="CPF" disabled color="white" />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: 'preenche seu email.' },
                        ]}
                      >
                        <Input
                          placeholder="E-mail"
                          color="white"
                          type="email"
                          disabled
                        />
                      </Form.Item>
                      <div>
                        <h2>Aniversário</h2>
                        <Form.Item
                          name={['user', 'birthdate']}
                          rules={[
                            {
                              required: true,
                              message: 'Data de aniversário obrigatória!',
                            },
                          ]}
                        >
                          <Input
                            type="date"
                            color="white"
                            placeholder="Aniversário"
                          />
                        </Form.Item>
                      </div>
                    </Grid>
                  </div>
                )}

                <div className="grid">
                  <h2>Endereço</h2>
                  <Grid columns={columns}>
                    <Form.Item
                      name={['address', 'postal_code']}
                      rules={[
                        { required: true, message: 'preenche seu cidade.' },
                      ]}
                    >
                      <Input
                        mask="99999-999"
                        placeholder="CEP"
                        color="white"
                        onChange={handleZipCode}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['address', 'country']}
                      rules={[
                        { required: true, message: 'Preencha seu País.' },
                      ]}
                    >
                      <SelectInput
                        placeholder="País"
                        showSearch
                        onChange={(props: any, options: any) =>
                          handleChangeCountry(props, options)
                        }
                        color="white"
                      >
                        {Country.map(value => (
                          <Option
                            key={value.abbreviation}
                            value={value.abbreviation}
                            states={value.state}
                          >
                            {value.name}
                          </Option>
                        ))}
                      </SelectInput>
                    </Form.Item>
                    <Form.Item
                      name={['address', 'state']}
                      rules={[
                        { required: true, message: 'Preencha seu estado' },
                      ]}
                    >
                      <SelectInput
                        placeholder="Estado"
                        optionFilterProp="children"
                        color="white"
                      >
                        {states.map(value => (
                          <Option key={value.UF} value={value.UF}>
                            {value.name}
                          </Option>
                        ))}
                      </SelectInput>
                    </Form.Item>
                    <Form.Item
                      name={['address', 'city']}
                      rules={[
                        { required: true, message: 'preenche sua cidade.' },
                      ]}
                    >
                      <Input placeholder="Cidade" color="white" />
                    </Form.Item>
                    <Form.Item
                      name={['address', 'address']}
                      rules={[
                        {
                          required: true,
                          message: 'preenche seu endereço.',
                        },
                      ]}
                    >
                      <Input placeholder="endereco" color="white" />
                    </Form.Item>
                    <Form.Item name={['address', 'neighborhood']}>
                      <Input placeholder="Bairro" color="white" />
                    </Form.Item>

                    {exludeUser && (
                      <div>
                        <h2>Aniversário</h2>
                        <Form.Item
                          name={['user', 'birthdate']}
                          rules={[
                            {
                              required: true,
                              message: 'Data de aniversário obrigatória!',
                            },
                          ]}
                        >
                          <Input
                            type="date"
                            color="white"
                            placeholder="Aniversário"
                          />
                        </Form.Item>
                      </div>
                    )}
                  </Grid>
                </div>
              </div>
              <div className="grid-form-radio">
                <h2>Outros</h2>
                <div className="form-radio">
                  <Form.Item
                    name={['user', 'isBreastfeeding']}
                    label="É lactante?"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    name={['user', 'hasAllergies']}
                    label="É alérgica(o)?"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    name={['user', 'isPregnant']}
                    label="É gestante?"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </div>
              </div>
              <br />
              <Form.Item>
                <ButtonContainer type="submit" loading={loading}>
                  Atualizar
                </ButtonContainer>
              </Form.Item>
            </Form>
          )}
        </>
      ) : (
        <Skeleton active />
      )}
    </Container>
  );
};

export default ProfileForm;
