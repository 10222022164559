import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface IExpand {
  expand: boolean;
}

export const Container = styled.div`
  display: block;
  width: 100%;
  max-width: 800px;
  margin: 0px auto;

  h3 {
    text-align: center;
    font-size: 28px;
    color: #666;
  }
`;

export const Card = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 4px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  h2 {
    margin: 0;
    color: #688053;
    font-weight: bold;
    font-size: 22px;

    @media only screen and (min-width: 1280px) {
      font-size: 28px;
    }

    @media only screen and (min-width: 1480px) {
      font-size: 28px;
    }
  }

  .top-head {
    display: block;
    position: relative;

    .status {
      display: flex;
      position: absolute;
      color: #fff;
      top: 0px;
      right: 0px;
      font-size: 12px;
      border-radius: 6px;
      padding-left: 12px;
      padding-right: 12px;
    }

    p {
      font-size: 13px;
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;

    .due-date {
      margin-top: 6px;
    }

    .btn-status {
      display: flex;
      margin-top: 6px;
    }

    @media only screen and (min-width: 1280px) {
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;

      .due-date {
        margin-top: 0px;
      }

      .btn-status {
        display: flex;
        margin-top: 0px;
      }
    }

    @media only screen and (min-width: 1480px) {
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;

      .due-date {
        margin-top: 0px;
      }

      .btn-status {
        display: flex;
        margin-top: 0px;
      }
    }

    .btn {
      border-radius: 12px;
      padding: 2px 12px;
    }

    .green {
      background: #58a82d;
      color: #fff;
    }

    .green-p {
      font-size: 12px;
      color: #58a82d;
      margin-top: 8px;

      @media only screen and (min-width: 1280px) {
        font-size: 13px;
        margin-top: 0px;
      }

      @media only screen and (min-width: 1480px) {
        font-size: 13px;
        margin-top: 0px;
      }
    }

    .green-dark {
      background: #586651;
    }

    .orange {
      background: #ff9900;
      color: #fff;
    }

    .gray {
      background: #a6a6a6;
      color: #fff;
    }

    .more-details {
      display: flex;
      align-items: center;
      border-radius: 12px;
      border: 0;
      color: #fff;
      padding: 2px 12px;
      font-size: 12px;

      @media only screen and (min-width: 1280px) {
        font-size: 14px;
      }

      @media only screen and (min-width: 1480px) {
        font-size: 14px;
      }

      &:hover {
        background: ${shade(0.3, '#586651')};
      }
    }

    span {
      p {
        margin: 0;
      }

      & + span {
        margin-left: 16px;
      }
    }
  }
`;

export const Expand = styled.div<IExpand>`
  ${props =>
    props.expand
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  flex-direction: column;

  .table {
    overflow-x: auto;

    .empty {
      background: #eee;
      padding: 6px;
      text-align: center;
      font-size: 12px;
    }

    ::-webkit-scrollbar {
      height: 4px;
    }

    p {
      font-size: 12px;
      color: #58a82d;
      font-weight: 300;
      margin-left: 12px;
      margin-top: 0;
      margin-bottom: 0;

      @media only screen and (min-width: 1280px) {
        font-size: 14px;
      }

      @media only screen and (min-width: 1480px) {
        font-size: 14px;
      }
    }

    .flex {
      display: flex;
      align-items: center;

      span {
        flex-wrap: nowrap;
        white-space: nowrap;
      }

      hr {
        margin-left: 6px;
      }
    }
  }

  hr {
    width: 100%;
    border: 1px dotted #ccc;
  }

  thead {
    color: #58a82d;
    font-weight: 300;
    font-size: 12px;
  }

  tbody {
    font-size: 12px;
  }

  .green {
    color: #58a82d;
  }

  .orange {
    color: #ff9900;
  }

  .red {
    color: red;
  }
`;

export const GridLoad = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  p {
    text-align: center;
    font-size: 13px;
    margin-top: 4px;
  }
`;
