import React from 'react';

import { AuthProvider } from './auth';
import { FieldsProvider } from './fields';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <FieldsProvider>{children}</FieldsProvider>
  </AuthProvider>
);

export default AppProvider;
