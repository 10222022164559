import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react-web';

import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import ButtonComponent from 'components/Button/custom';
import Loading from 'components/Loading/spin';

import { useAuth } from 'hooks/auth';

import { valueToCurrency } from 'utils/format';

import {
  plansLoadRequest,
  planSelectRequest,
  validateTermPlanRequest,
} from 'store/modules/plans/action';

import { plansUseSelector, IPlans } from 'store/modules/plans/types';

import animationJson from 'assets/loading-skeleton.json';

import { Card } from './styled';

const planNotFirstAttendance = ({ discountPrice, plan, onSelect }: any) => {
  return (
    <>
      <h2>Plano SD Lover</h2>
      <div className="description">
        <p>
          Seja um <strong>SD Lover</strong> e garanta o seu{' '}
          <strong>tratamento completo!</strong>
        </p>
        <p>Deixando o seu olhar sempre único</p>
      </div>
      <div className="price-description">
        Economize <strong>{valueToCurrency(discountPrice)}</strong> em 1 ano.
        <br />
        De {valueToCurrency(plan.original_price)}{' '}
        <span>
          <strong>Por: {valueToCurrency(plan.price)}</strong>
        </span>
        <div className="btn-buy" style={{ marginTop: 4 }}>
          <ButtonComponent onClick={() => onSelect(plan)}>
            <span className="btn-buy">
              <strong>COMEÇAR AGORA</strong>
            </span>
          </ButtonComponent>
        </div>
      </div>
    </>
  );
};

const planFirstAttendance = ({ handleIsFirstAttendance }: any) => {
  return (
    <>
      <h2>Parabéns</h2>
      <div className="description-first">
        <p>
          Você acaba de ganhar o seu primeiro design de sobrancelhas{' '}
          <strong>gratuito!</strong>
        </p>
      </div>
      <div className="price-description">
        <div className="btn-buy" style={{ marginTop: 4 }}>
          <ButtonComponent onClick={handleIsFirstAttendance}>
            <span className="btn-buy">
              <strong>SAIBA MAIS</strong>
            </span>
          </ButtonComponent>
        </div>
      </div>
    </>
  );
};

const Choice: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userMe } = useAuth();
  const { data: plans } = useSelector(plansUseSelector);
  const [plan, setPlan] = useState<IPlans>({} as IPlans);
  const [isFirstAttendance, setIsFirstAttendance] = useState(false);

  useEffect(() => {
    if (plans.length) {
      const filterPlans = plans.filter(item => item.isRecommended)[0] || {};

      setPlan(filterPlans as IPlans);
    }
  }, [plans, userMe]);

  useEffect(() => {
    if (Object.keys(userMe).length && !Object.keys(plan).length) {
      dispatch(
        plansLoadRequest({
          is_first_attandance: !!userMe.isFirstServiceAtSD,
        }),
      );
    }
  }, [userMe, plan]); //eslint-disable-line

  const onSelect = (values: any) => {
    dispatch(planSelectRequest(values));
    dispatch(validateTermPlanRequest({ check: false }));
    history.push('/purchase');
  };

  const handleIsFirstAttendance = () => {
    setIsFirstAttendance(true);
  };

  let discountPrice = 0;
  const loading = true;

  if (Object.keys(plan).length) {
    const originalPriceYear = plan.original_price * 12;
    const priceYear = plan.price * 12;
    discountPrice = originalPriceYear - priceYear;
  }

  return (
    <>
      <Helmet>
        <title>Planos | Portal do Cliente</title>
      </Helmet>
      <Card>
        <div className="card">
          {loading && !Object.keys(plan).length ? (
            <>
              {isMobile ? (
                <Loading color="#999" />
              ) : (
                <Lottie options={{ animationData: animationJson }} />
              )}
            </>
          ) : (
            <>
              {isFirstAttendance ? (
                <div className="content">
                  <h2>Falta pouco!</h2>
                  <div className="description-first">
                    <p>
                      Olá {userMe.name}, o <strong>SD Lover</strong> é uma
                      assinatura trimestral de design de sobrancelhas.
                      <br />
                      Assine e ganhe e sua primeira experiência{' '}
                      <strong>gratuita*</strong> e tenha descontos exclusivos
                      nos próximos atendimentos.
                    </p>
                    <div style={{ marginBottom: 12 }}>
                      <small>
                        *Promoção primeiro design gratuito é válida apenas para
                        novos clientes.
                        <br />
                        *Você tem até 30 dias para utilizar seu design de
                        sobrancelhas gratuito, após 30 dias será cobrado o valor
                        da assinatura de R$ {valueToCurrency(plan.price)}. Caso
                        não queira continua com a assinatura, você pode cancelar
                        entrando em contato com sua unidade.
                        <br />
                        *Cancelando nos primeiros 30 dias nada será cobrado.
                      </small>
                    </div>
                  </div>
                  <div className="price-description">
                    <div className="btn-buy" style={{ marginTop: 4 }}>
                      <ButtonComponent onClick={() => onSelect(plan)}>
                        <span className="btn-buy">
                          <strong>CONTINUAR</strong>
                        </span>
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="img">
                    <img
                      src="/static/images/modelo-ad.png"
                      alt="Sobrancelhas Design"
                    />
                  </div>
                  <div className="content">
                    {userMe.isFirstServiceAtSD ? (
                      <>{planFirstAttendance({ handleIsFirstAttendance })}</>
                    ) : (
                      <>
                        {planNotFirstAttendance({
                          discountPrice,
                          plan,
                          onSelect,
                        })}
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default Choice;
