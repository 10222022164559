import React, { useContext, useState, useCallback, createContext } from 'react';
import { addMonths, subMonths, format } from 'date-fns';

interface CalendarContextData {
  events: any;
  weekStartsOn: any;
  currentMonth: any;
  setCurrentMonth: any;
  selectedDate: any;
  setSelectedDate: any;
  prevMonth: any;
  nextMonth: any;
  getData: any;
  getDate: any;
  handleOpen(data: any): any;
  handleClose(): any;
  handleDate(data: string): any;
}

const CalendarContext = createContext<CalendarContextData>(
  {} as CalendarContextData,
);

const CalendarProvider: React.FC<any> = ({
  children,
  events,
  weekStartsOn,
}) => {
  const [currentMonth, setCurrentMonth] = useState<any>(new Date());
  const [selectedDate, setSelectedDate] = useState<any>('');
  const [getDate, setGetDate] = useState(new Date());
  const [getData, setGetData] = useState<any>({
    title: '',
    date: '',
    open: false,
    info: [],
  });

  const prevMonth = () => setCurrentMonth(subMonths(currentMonth, 1));
  const nextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));

  const handleOpen = useCallback((event: any) => {
    const title = `Dia do calendário: ${format(
      new Date(event.date),
      'dd-MM-yyy',
    )}`;

    setGetData({
      title,
      open: true,
      info: event.data,
    });
  }, []);

  const handleDate = useCallback((event: any) => {
    setGetDate(event);
  }, []);

  const handleClose = useCallback(() => {
    setGetData({
      title: '',
      open: false,
      info: [],
    });
  }, []);

  return (
    <CalendarContext.Provider
      value={{
        events,
        weekStartsOn,
        currentMonth,
        setCurrentMonth,
        selectedDate,
        setSelectedDate,
        prevMonth,
        nextMonth,
        handleOpen,
        handleClose,
        handleDate,
        getData,
        getDate,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

function useCalendar(): CalendarContextData {
  const context = useContext(CalendarContext);

  if (!context) {
    throw new Error(' useCalendar must be used within an authProvider ');
  }
  return context;
}

export { CalendarProvider, useCalendar };
