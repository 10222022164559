import React, { useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { isMobile } from 'react-device-detect';

import { useAuth } from 'hooks/auth';
import { usePosition } from 'hooks/position';

import { creditCardUnMask } from 'utils/mask';
import { nameCase, valueToCurrency } from 'utils/format';

import { subscriptionsCreateRequest } from 'store/modules/subscriptions/action';
import { subscriptionsUseSelector } from 'store/modules/subscriptions/types';

import {
  planSelectRequest,
  plansLoadRequest,
} from 'store/modules/plans/action';
import { plansUseSelector, IPlans } from 'store/modules/plans/types';

import Button from 'components/Button/custom';
import Input from 'components/Input';
import Select from 'components/Select/custom';
import Loading from 'components/Loading';
import message from 'components/Message';
import { ModalComponent } from 'components/Modal';

import { months, years } from 'utils/calendar';

import { Term } from '../term';

import {
  ContainerPayment,
  ButtonContainer,
  ContainerInfoPayment,
} from './styled';

interface IOnFinishValues {
  cardNumber: string;
  cardName: string;
  cardExpiry: string;
  cardCvc: string;
  month: string;
  year: string;
}

const Payment: React.FC = () => {
  const { userMe } = useAuth();
  const [position, positionError] = usePosition();
  const { unit } = userMe;

  const dispatch = useDispatch();
  const { data: plans, selectedPlan, validateTerm } = useSelector(
    plansUseSelector,
  );
  const { loadingCreate } = useSelector(subscriptionsUseSelector);

  const [fields, setFields] = useState<any>([]);
  const [cardNumber, setCardNumber] = useState<any>('');
  const [cardMonth, setCardMonth] = useState('');
  const [cardYear, setCardYear] = useState('');
  const [cardCvc, setCardCvc] = useState<any>('');
  const [cardName, setCardName] = useState<any>('');
  const [focused, setFocused] = useState<any>('number');
  const [planNormal, setPlanNormal] = useState<IPlans>({} as IPlans);
  const [planPlus, setPlanPlus] = useState<IPlans>({} as IPlans);
  const [modalTerm, setModalTerm] = useState(false);

  useEffect(() => {
    if (Object.keys(userMe).length) {
      dispatch(
        plansLoadRequest({
          is_first_attandance: !!userMe.isFirstServiceAtSD,
        }),
      );
    }
  }, [userMe]); //eslint-disable-line

  useEffect(() => {
    if (plans.length > 0) {
      const findPlanPlus = plans.find(item => !item.isRecommended);
      const findPlan = plans.find(item => item.isRecommended);
      setPlanPlus(findPlanPlus as IPlans);
      setPlanNormal(findPlan as IPlans);
    }
  }, [plans]);

  const onCardName = (data: any) => {
    const { value, name } = data.target;
    setCardName(nameCase(value));
    setFocused('name');
    setFields([{ name: [name], value: nameCase(value) }]);
  };

  const onFinish = (value: IOnFinishValues) => {
    if (positionError || !position) {
      message.error(
        'Por gentileza, permita acesso a localização e recarregue a página.',
      );
      return;
    }

    if (selectedPlan) {
      dispatch(
        subscriptionsCreateRequest({
          ...position,
          planUser: selectedPlan.id,
          customerIdentifier: userMe.identifier,
          cardNumber: creditCardUnMask(value.cardNumber),
          cardExpiry: `${value.month}-${value.year}`,
          isDigitalTerm: validateTerm || false,
          cardCvc: value.cardCvc,
          cardName: value.cardName,
        }),
      );
    } else {
      message.warning('Selecione um plano');
    }
  };

  const handleSelectedPlan = (event: any) => {
    const trueOrFalse = !event.target.checked;
    const findPlan = plans.find(item => item.isRecommended === trueOrFalse);
    if (findPlan) {
      dispatch(planSelectRequest(findPlan));
    }
  };

  const services: any[] = [];

  if (selectedPlan.services.length) {
    selectedPlan.services.forEach((item: any) => {
      services.push(item.name);
    });
  }

  return (
    <>
      <ContainerPayment>
        <Loading loading={!!loadingCreate}>
          <Form
            name="payment_data"
            fields={fields}
            id="form-payment"
            onFinish={value => onFinish(value)}
          >
            <div className="grid">
              <div className="containerpayment">
                <div className="cardpayment">
                  <div className="gridtemplate_one">
                    <Form.Item
                      name="cardNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Insira do número do cartão',
                          min: 19,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Número do cartão"
                        name="cardNumber"
                        mask="9999 9999 9999 9999"
                        onKeyUp={(event: any) => {
                          setCardNumber(event.target.value);
                          setFocused('number');
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="cardName"
                      rules={[
                        {
                          required: true,
                          message: 'Insira o nome do titular do cartão',
                        },
                      ]}
                    >
                      <Input
                        name="cardName"
                        onChange={(value: any) => onCardName(value)}
                        placeholder="Titular do cartão"
                      />
                    </Form.Item>
                  </div>
                  <div className="gridtemplate_two">
                    <Form.Item
                      name="month"
                      rules={[
                        {
                          required: true,
                          message: 'Selecione o mês',
                        },
                      ]}
                    >
                      <Select
                        name="month"
                        onChange={(event: any) =>
                          setCardMonth(event.target.value)
                        }
                      >
                        <option value="">Mês</option>
                        {months.map((item: any) => (
                          <option key={item.month} value={item.month}>
                            {item.month} - {item.name}
                          </option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="year"
                      rules={[
                        {
                          required: true,
                          message: 'Selecione o ano',
                        },
                      ]}
                    >
                      <Select
                        name="year"
                        onChange={(event: any) =>
                          setCardYear(event.target.value.substring(2, 4))
                        }
                      >
                        <option value="">Ano</option>
                        {years(15).map((item: any) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="gridtemplate_one">
                    <Form.Item
                      name="cardCvc"
                      rules={[
                        {
                          required: true,
                          message: 'Insira o código do cartão',
                        },
                      ]}
                    >
                      <Input
                        placeholder="CVC"
                        name="cardCvc"
                        mask="9999"
                        onKeyUp={(event: any) => {
                          setCardCvc(event.target.value);
                          setFocused('cvc');
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              {!isMobile && (
                <Cards
                  number={cardNumber}
                  name={cardName}
                  expiry={`${cardMonth}${cardYear}`}
                  cvc={cardCvc}
                  focused={focused}
                  locale={{ valid: 'Validade do cartão' }}
                />
              )}
            </div>

            <ContainerInfoPayment>
              <div className="unit">
                <div className="unit-info">
                  <strong>Unidade escolhida</strong> <p>{unit.name}</p>
                </div>
                <div className="unit-price">
                  {selectedPlan.isFirstAttendance ? (
                    <>
                      <small>Primeiro Design</small>
                      <div className="text-price">R$ 00,00</div>
                      <div className="sub-title">
                        {selectedPlan.isRecommended ? (
                          <>
                            Após 30 dias {valueToCurrency(selectedPlan.price)} /
                            mês
                          </>
                        ) : (
                          <>
                            Após 30 dias {valueToCurrency(planNormal.price)} +{' '}
                            {valueToCurrency(planPlus.price - planNormal.price)}{' '}
                            Epilação Buço, total de{' '}
                            {valueToCurrency(planPlus.price)} /mês
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="text-price">
                      {valueToCurrency(selectedPlan.price)}
                    </div>
                  )}
                </div>
              </div>
              <div className="add-plan">
                <input type="checkbox" onClick={handleSelectedPlan} />
                Adicionar Epilação de Buço por +{' '}
                {planPlus?.price &&
                  valueToCurrency(planPlus.price - planNormal.price)}
              </div>
              <div className="term">
                Ao clicar no botão &quot;Iniciar assinatura&quot; abaixo, você
                concorda com nossos{' '}
                <span>
                  <button type="button" onClick={() => setModalTerm(true)}>
                    Termos de uso
                  </button>
                </span>
                , confirma ter mais de 18 anos e aceita que a SD renove
                automaticamente sua assinatura e cobre o preço da assinatura
                (atualmente {valueToCurrency(selectedPlan.price)}/mês, após os
                30 dias da primeira adesão) da sua forma de pagamento até você
                cancelar. Você pode cancelar quando quiser para evitar cobranças
                futuras. Para cancelar, acesse a página &quot;Minha
                Unidade&quot; e entre em contato com sua unidade.
              </div>
            </ContainerInfoPayment>

            <ButtonContainer>
              <Button type="submit">
                <h2>Iniciar Assinatura</h2>
              </Button>
            </ButtonContainer>
          </Form>
        </Loading>
      </ContainerPayment>

      <ModalComponent
        open={modalTerm}
        title="Termo"
        handleCancel={() => setModalTerm(false)}
        content={<Term />}
      />
    </>
  );
};

export default Payment;
