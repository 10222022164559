import styled, { css } from 'styled-components';
import { Input, DatePicker as DatePickerInput } from 'antd';

export const InputText = styled(Input)`
  border: 0;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;

  ${(props: any) =>
    props.color === 'white'
      ? css`
          background: #fff !important;

          > .ant-input {
            background: #fff !important;
            font-size: 18px;
            font-weight: 500;
            color: #686868;
            margin-left: 10px;
            text-transform: ${(props: any) => props?.theme};
          }
        `
      : css`
          background: #e1d9d8 !important;

          > .ant-input {
            background: #e1d9d8 !important;
            font-size: 18px;
            font-weight: 500;
            color: #686868;
            margin-left: 10px;
            text-transform: ${(props: any) => props?.theme};
          }
        `}

  ${(props: any) =>
    props.isFocused &&
    css`
      box-shadow: 1px 1px 4px 0px #888888;
    `}

  > &::placeholder {
    color: #999;
  }

  > .ant-input-prefix {
    color: #9ea58a !important;

    > .anticon {
      border-right: 1px solid;
      padding: 5px 10px 5px 5px;
      font-size: 20px;
    }
  }
`;

export const InputDate = styled.input`
  border: 0;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  color: #686868;
  padding-left: 6px;
  padding-right: 6px;

  ${(props: any) =>
    props.color === 'white'
      ? css`
          background: #fff !important;
        `
      : css`
          background: #e1d9d8 !important;
        `}
`;

export const InputPassword = styled(Input.Password)`
  border: 0;
  height: 50px;
  border-radius: 8px;
  color: #686868;

  ${(props: any) =>
    props.color === 'white'
      ? css`
          background: #fff !important;

          > .ant-input {
            background: #fff !important;
            font-size: 18px;
            font-weight: 500;
            color: #686868;
            margin-left: 10px;
            text-transform: ${(props: any) => props?.theme};
          }
        `
      : css`
          background: #e1d9d8 !important;

          > .ant-input {
            background: #e1d9d8 !important;
            font-size: 18px;
            font-weight: 500;
            color: #686868;
            margin-left: 10px;
            text-transform: ${(props: any) => props?.theme};
          }
        `}

  > &::placeholder {
    color: #999;
  }

  > .ant-input-prefix {
    color: #9ea58a !important;

    > .anticon {
      border-right: 1px solid;
      padding: 5px 10px 5px 5px;
      font-size: 20px;
    }
  }
`;

export const DataPicker = styled(DatePickerInput)`
  border: 0;
  height: 50px;
  font-size: 18px;
  width: 100%;
  border-radius: 8px;

  ${(props: any) =>
    props.color === 'white'
      ? css`
          > .ant-picker-input {
            background: #fff !important;
          }

          > .ant-input {
            background: #fff !important;
            font-size: 18px;
            font-weight: 500;
            color: #686868;
            margin-left: 10px;
            text-transform: ${(props: any) => props?.theme};
          }
        `
      : css`
          background: #e1d9d8 !important;

          > .ant-input {
            background: #e1d9d8 !important;
            font-size: 18px;
            font-weight: 500;
            color: #686868;
            margin-left: 10px;
            text-transform: ${(props: any) => props?.theme};
          }
        `}

  > .ant-picker-input {
    > input {
      font-size: 18px;
      font-weight: 500;
      text-transform: initial;
      color: #686d58;
      margin-left: 10px;
    }
  }
`;
