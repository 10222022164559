import React from 'react';

import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { useCalendar } from 'hooks/calendar';

import { Header } from './styles';

const CalendarHeader: React.FC<any> = () => {
  const {
    currentMonth,
    setCurrentMonth,
    nextMonth,
    prevMonth,
  }: any = useCalendar();

  if (!currentMonth || !setCurrentMonth) return null;

  return (
    <Header>
      <button type="button" onClick={prevMonth}>
        <MdKeyboardArrowLeft />
      </button>
      <div className="calendar-current">
        {format(currentMonth, "MMMM 'de' yyyy", {
          locale: pt,
        })}
      </div>
      <button type="button" className="calendar-next" onClick={nextMonth}>
        <MdKeyboardArrowRight />
      </button>
    </Header>
  );
};

export default CalendarHeader;
