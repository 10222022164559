import { combineReducers } from 'redux';

import units from './units';
import plans from './plans';
import subscriptions from './subscriptions';

export default combineReducers({
  units,
  plans,
  subscriptions,
});
