import React from 'react';
import { isSameMonth, isSunday, isToday, format, isPast } from 'date-fns';

import { MdBrightness1 } from 'react-icons/md';

import { useCalendar } from 'hooks/calendar';

import { Button, Info } from './styles';

const CalendarCell: React.FC<any> = ({ date }) => {
  const {
    events,
    currentMonth,
    setSelectedDate,
    getDate,
    handleDate,
  } = useCalendar();

  if (!currentMonth || !setSelectedDate) return null;

  const formatDate = format(date, 'yyyy-MM-dd');
  const formatGetDate = format(getDate, 'yyyy-MM-dd');

  const thisDate = date;

  let classes = 'calendar-cell';

  const verifyIsPast = isPast(date);

  if (formatDate === formatGetDate) {
    classes += ' calendar-cell--selected';
  }

  if (!isSameMonth(date, currentMonth)) {
    classes += ' calendar-cell--disabled';
  }

  if (isSunday(new Date(date))) {
    classes += ' calendar-cell--sunday';
  }

  return (
    <>
      {events.length > 0 &&
      events.filter(
        (event: any) =>
          event.week === format(date, 'iiii') &&
          event.type === 'closed' &&
          !event.closed,
      ).length > 0 ? (
        <>
          <Button
            className={classes}
            onClick={() => {
              isSameMonth(thisDate, currentMonth);
              setSelectedDate(thisDate);
              handleDate(thisDate);
            }}
            disabled
          >
            <div className="calendar-cell__date">{format(date, 'd')}</div>

            <Info>
              <MdBrightness1 color="#ff4d4d" size={7} />
            </Info>
          </Button>
        </>
      ) : (
        <>
          {!isSameMonth(date, currentMonth) ? (
            <Button
              className={classes}
              onClick={() => {
                isSameMonth(thisDate, currentMonth);
                setSelectedDate(thisDate);
                handleDate(thisDate);
              }}
              disabled
            >
              <div className="calendar-cell__date">{format(date, 'd')}</div>
            </Button>
          ) : (
            <Button
              className={classes}
              onClick={() => {
                isSameMonth(thisDate, currentMonth);
                setSelectedDate(thisDate);
                handleDate(thisDate);
              }}
              disabled={isToday(date) ? false : verifyIsPast}
            >
              <div className="calendar-cell__date">{format(date, 'd')}</div>
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default CalendarCell;
