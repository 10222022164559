import React from 'react';

import Spin from '../spin';

import { Container } from './styles';

interface IBackgroundLoading {
  loading: boolean;
}

export const BackgroundLoading: React.FC<IBackgroundLoading> = ({
  loading,
}) => {
  return (
    <Container loading={loading}>
      <div>
        <Spin color="#555555" fontSize={28} />
        <small>carregando</small>
      </div>
    </Container>
  );
};
