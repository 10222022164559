import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import { useAuth } from 'hooks/auth';

import { plansUseSelector } from 'store/modules/plans/types';
import { subscriptionsUseSelector } from 'store/modules/subscriptions/types';

import Loading from 'components/Loading/spin';
import { contractTemplate } from 'utils/contractTemplate';

import { ContainerContract } from './styles';

export const Term: React.FC = () => {
  const { userMe } = useAuth();
  const { selectedPlan } = useSelector(plansUseSelector);
  const { contract, loading } = useSelector(subscriptionsUseSelector);
  const [contractRes, setContractRes] = useState('');

  useEffect(() => {
    if (Object.keys(contract).length) {
      const insertDataContract = contractTemplate({
        plan: selectedPlan,
        user: userMe,
        contract,
      });
      setContractRes(insertDataContract);
    }
  }, [contract, userMe, selectedPlan]);

  const validContract = !!Object.keys(contract).length;

  return (
    <ContainerContract>
      {!validContract && loading ? (
        <Loading color="#999" />
      ) : (
        <div className="contract">{ReactHtmlParser(contractRes)}</div>
      )}
    </ContainerContract>
  );
};
