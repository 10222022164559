import moment from 'moment';

const dateFormat = (date: any, formatDate?: string) => {
  if (formatDate) {
    return moment(date).format(formatDate);
  }
  return moment(date).format('DD/MM/YYYY - HH:mm');
};

const dateFormatSimple = (date: any) => {
  return moment(date).format('DD/MM/YYYY');
};

const emailToName = (email: string) => {
  const name: string[] = email.split('@');
  return name[0].replace('.', ' ');
};

const valueToCurrency = (value: number): string => {
  return !value
    ? ''
    : value.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL',
      });
};

const toNumber = (value: any) => {
  return value.replace(/([^\d])+/gim, '');
};

const caseExceptions = ['de', 'da', 'das', 'do', 'dos'];

const nameCase = (str: any) =>
  str
    .toLowerCase()
    .split(' ')
    .map((w: any) =>
      !w || caseExceptions.includes(w)
        ? w
        : w[0].toUpperCase() + w.substring(1),
    )
    .join(' ');

export {
  emailToName,
  dateFormat,
  dateFormatSimple,
  valueToCurrency,
  nameCase,
  toNumber,
};
