import React from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface ISpinLoading {
  fontSize?: number;
  color?: string;
}

const SpinLoading: React.FC<ISpinLoading> = ({
  fontSize = 20,
  color = '#fff',
}) => {
  const antIcon = <LoadingOutlined style={{ fontSize, color }} spin />;

  return <Spin indicator={antIcon} />;
};

export default SpinLoading;
