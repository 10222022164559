import styled from 'styled-components';

export const Background = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 99;
  background: url('/static/images/bg-bubble.jpg') no-repeat right bottom;
  background-size: 70% auto;
  padding-bottom: 300px;

  @media only screen and (min-width: 1024px) {
    height: 100vh;
    padding-bottom: 0px;
    background-size: 40% auto;
  }

  @media only screen and (min-width: 1280px) {
    height: 100vh;
    padding-bottom: 0px;
    background-size: 50% auto;
  }

  @media only screen and (min-width: 1480px) {
    height: 100vh;
    padding-bottom: 0px;
    background-size: 50% auto;
  }
`;

export const GridForm = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(0%);
  padding: 16px;

  @media only screen and (min-width: 1024px) {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    padding: 0px;
  }

  @media only screen and (min-width: 1280px) {
    width: 600px;
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    padding: 0px;
  }

  @media only screen and (min-width: 1480px) {
    width: 700px;
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    padding: 0px;
  }

  .text-or {
    text-align: center;
    margin-top: 12px;
  }

  .grid-text {
    color: #666666;

    h4 {
      font-size: 22px;
      font-weight: bold;
      color: #666666;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }

    @media only screen and (min-width: 1024px) {
      h4 {
        font-size: 26px;
      }

      p {
        font-size: 22px;
        line-height: 26px;
      }
    }

    @media only screen and (min-width: 1280px) {
      h4 {
        font-size: 26px;
      }

      p {
        font-size: 22px;
        line-height: 26px;
      }
    }

    @media only screen and (min-width: 1480px) {
      h4 {
        font-size: 26px;
      }

      p {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }

  .grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    div {
      width: 100%;
    }

    div + div {
      margin-left: 0px;
    }

    button + button {
      margin-left: 0px;
      margin-top: 14px;
    }

    @media only screen and (min-width: 1024px) {
      flex-direction: row;

      div + div {
        margin-left: 14px;
      }

      button + button {
        margin-left: 14px;
        margin-top: 0px;
      }
    }

    @media only screen and (min-width: 1280px) {
      flex-direction: row;

      div + div {
        margin-left: 14px;
      }

      button + button {
        margin-left: 14px;
        margin-top: 0px;
      }
    }

    @media only screen and (min-width: 1480px) {
      flex-direction: row;

      div + div {
        margin-left: 14px;
      }

      button + button {
        margin-left: 14px;
        margin-top: 0px;
      }
    }
  }
`;
