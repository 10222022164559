import React from 'react';

import ReactHtmlParser from 'react-html-parser';
import { Slide, Fade } from 'react-awesome-reveal';

import { useAuth } from 'hooks/auth';

import FormGrid from '../include/form';
import { description } from '../include/geral';

import { Container, Header, Content, Footer } from '../styles';

interface IDesktop {
  loading: boolean;
  form: any;
  handleLogin: any;
  nameUser: string;
  handleSendCode: any;
  handleIsPasswordReset: any;
  isPasswordReset: string;
  handleLoginResetPassword: any;
  loadingReset: boolean;
}

const Desktop: React.FC<IDesktop> = ({
  loading,
  form,
  handleLogin,
  nameUser,
  handleSendCode,
  handleIsPasswordReset,
  isPasswordReset,
  handleLoginResetPassword,
  loadingReset,
}) => {
  const { isPassword, handleIsPassword } = useAuth();

  return (
    <Container>
      <Header>
        <Content>
          <div className="container-header">
            <img src="/static/images/logo.svg" alt="Grupo SD" />
            <div className="description-welcome">
              <h4>
                Seja bem-vinda, <strong>SD LOVER!</strong>
              </h4>
              <p>
                Uma <strong>experiência completa</strong> para você cuidar
                sempre do <strong>seu olhar.</strong>
              </p>
            </div>
          </div>
          <div className="bg-woman">
            <Slide>
              <img src="/static/images/bg-woman.png" alt="Grupo SD" />
            </Slide>
          </div>
          <Fade>
            <FormGrid
              loading={loading}
              isPassword={isPassword}
              handleLogin={handleLogin}
              form={form}
              handleIsPassword={handleIsPassword}
              nameUser={nameUser}
              isPasswordReset={isPasswordReset}
              handleSendCode={handleSendCode}
              handleIsPasswordReset={handleIsPasswordReset}
              handleLoginResetPassword={handleLoginResetPassword}
              loadingReset={loadingReset}
            />
          </Fade>
        </Content>
      </Header>
      <Footer>
        <div className="description">{ReactHtmlParser(description())}</div>
      </Footer>
    </Container>
  );
};

export default Desktop;
