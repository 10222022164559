import { action } from 'typesafe-actions';

import {
  PlansTypes,
  IPlans,
  IValidateTermPlan,
  IPlansIsFirstAttandance,
} from './types';

export const plansLoadRequest = (data: IPlansIsFirstAttandance) =>
  action(PlansTypes.LOAD_PLANS_REQUEST, data);

export const plansLoadSuccess = (data: IPlans[]) =>
  action(PlansTypes.LOAD_PLANS_SUCCESS, { data });

export const plansLoadFailure = () => action(PlansTypes.LOAD_PLANS_FAILURE);

export const planSelectRequest = (data: IPlans) =>
  action(PlansTypes.SELECT_PLANS_REQUEST, { data });

export const validateTermPlanRequest = (data: IValidateTermPlan) =>
  action(PlansTypes.VALIDATE_PLANS_REQUEST, { data });

export const plansClearSelectedRequest = () =>
  action(PlansTypes.CLEAR_SELECTED_PLANS_REQUEST);

export const planResetRequest = () => action(PlansTypes.RESET_PLANS_REQUEST);
