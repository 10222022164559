import React, { PropsWithChildren, ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

interface ILoading extends PropsWithChildren<any> {
  size?: number;
  loading: boolean;
  children: ReactNode;
}

const defaultProps = {
  size: 36,
};

const Loading: React.FC<ILoading> = (props: ILoading) => (
  <Spin
    indicator={<LoadingOutlined style={{ fontSize: props.size }} spin />}
    spinning={props.loading}
  >
    {props.children}
  </Spin>
);

Loading.defaultProps = defaultProps;

export default Loading;
