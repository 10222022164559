import { action } from 'typesafe-actions';

import {
  SubscriptionsTypes,
  ISubscriptions,
  ISubscriptionsCreate,
  ISubscriptionCheck,
  IContract,
} from './types';

export const contractLoadRequest = () =>
  action(SubscriptionsTypes.LOAD_CONTRACT_REQUEST);

export const contractLoadSuccess = (data: IContract) =>
  action(SubscriptionsTypes.LOAD_CONTRACT_SUCCESS, { data });

export const subscriptionsLoadRequest = () =>
  action(SubscriptionsTypes.LOAD_SUBSCRIPTIONS_REQUEST);

export const subscriptionsLoadSuccess = (data: ISubscriptions[]) =>
  action(SubscriptionsTypes.LOAD_SUBSCRIPTIONS_SUCCESS, { data });

export const subscriptionsLoadFailure = () =>
  action(SubscriptionsTypes.LOAD_SUBSCRIPTIONS_FAILURE);

export const subscriptionsCreateRequest = (data: ISubscriptionsCreate) =>
  action(SubscriptionsTypes.CREATE_SUBSCRIPTIONS_REQUEST, { data });

export const subscriptionsClearRequest = () =>
  action(SubscriptionsTypes.CLEAR_SUBSCRIPTIONS_REQUEST);

export const subscriptionsReceiptSuccess = (data: ISubscriptionCheck) =>
  action(SubscriptionsTypes.RECEIPT_SUBSCRIPTIONS_SUCCESS, { data });

export const subscriptionReceiptClear = () =>
  action(SubscriptionsTypes.RECEIPT_SUBSCRIPTIONS_CLEAR);

export const subscriptionConfirmationSuccess = (data: ISubscriptionCheck) =>
  action(SubscriptionsTypes.CONFIRMATION_SUBSCRIPTIONS_SUCCESS, { data });
