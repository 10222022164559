import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { statesBR } from 'utils/locales';

import Loading from 'components/Loading/spin';
import Button from 'components/Button/custom';

import { unitsLoadRequest, unitLinkRequest } from 'store/modules/units/action';
import { unitsUseSelector, IUnit } from 'store/modules/units/types';
import { FormUnit, LoadingGrid } from './styled';

import SelectCustom from '../Select/custom';

const SelectUnit: React.FC = () => {
  const dispatch = useDispatch();
  const { data: units, loadLink } = useSelector(unitsUseSelector);

  const [cities, setCities] = useState<any[]>([]);
  const [unitsSelected, setUnitsSelected] = useState<IUnit[]>([] as IUnit[]);
  const [unitSelected, setUnitSelected] = useState<IUnit>({} as IUnit);

  useEffect(() => {
    dispatch(unitsLoadRequest());
  }, []); // eslint-disable-line

  const handleChangeState = useCallback(
    event => {
      const result = units?.filter(
        value => value.region.indexOf(event.target.value) !== -1,
      );

      const arrcities = result.map(item => item.city);

      const uniqueCities = arrcities.filter((elem, pos, self): any => {
        const selfResult = self.indexOf(elem);
        return selfResult === pos;
      });

      setCities(uniqueCities);
    },
    [units],
  );

  const handleChangeCity = useCallback(
    (event: any) => {
      const result = units.filter(item => item.city === event.target.value);

      setUnitsSelected(result);
    },
    [units],
  );

  const handleChangeUnit = useCallback(
    (event: any) => {
      const result = units.find(item => item.email === event.target.value);

      setUnitSelected(result as IUnit);
    },
    [units],
  );

  const handleSubmit = useCallback(() => {
    dispatch(
      unitLinkRequest({
        email: unitSelected.email,
        id: String(unitSelected.id),
      }),
    );
  }, [dispatch, unitSelected]);

  return (
    <>
      {units.length ? (
        <FormUnit>
          <SelectCustom
            color="secondary"
            name="state"
            onChange={handleChangeState}
          >
            <option value="">Estado</option>
            {statesBR.map((region: any) => (
              <option key={region.UF} value={region.UF}>
                {region.name}
              </option>
            ))}
          </SelectCustom>

          <SelectCustom
            color="secondary"
            name="city"
            onChange={handleChangeCity}
          >
            <option value="">Cidade</option>
            {cities.map((item, index): any => (
              <option key={Number(index)} value={item}>
                {item}
              </option>
            ))}
          </SelectCustom>

          <SelectCustom
            color="secondary"
            name="unit"
            onChange={handleChangeUnit}
          >
            <option value="">Unidade mais próxima</option>
            {unitsSelected.map(item => (
              <option key={item.id} value={item.email}>
                {item.name}
              </option>
            ))}
          </SelectCustom>

          <div className="grid">
            <Button
              type="button"
              disabled={!Object.keys(unitSelected).length}
              loading={loadLink}
              onClick={handleSubmit}
            >
              Próximo
            </Button>
          </div>
        </FormUnit>
      ) : (
        <LoadingGrid>
          <Loading color="#666666" fontSize={26} /> <p>aguarde.</p>
        </LoadingGrid>
      )}
    </>
  );
};

export default SelectUnit;
