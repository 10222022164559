import { action } from 'typesafe-actions';

import { UnitsTypes, IUnit, ILinkUnit, IShowUnit, IGateWay } from './types';

export const unitsLoadRequest = () => action(UnitsTypes.LOAD_UNITS_REQUEST);

export const unitsLoadSuccess = (data: IUnit[]) =>
  action(UnitsTypes.LOAD_UNITS_SUCCESS, { data });

export const unitsShowRequest = (data: IShowUnit) =>
  action(UnitsTypes.SHOW_UNIT_REQUEST, { data });

export const unitsShowSuccess = (data: IUnit) =>
  action(UnitsTypes.SHOW_UNIT_SUCCESS, { data });

export const unitLinkLoad = () => action(UnitsTypes.LINK_UNIT_LOAD);

export const unitLinkRequest = (data: ILinkUnit) =>
  action(UnitsTypes.LINK_UNIT_REQUEST, { data });

export const unitsLoadFailure = () => action(UnitsTypes.LOAD_UNITS_FAILURE);

export const gatewayLoadRequest = (data: IShowUnit) =>
  action(UnitsTypes.LOAD_GATEWAY_REQUEST, { data });

export const gatewayLoadSuccess = (data: IGateWay) =>
  action(UnitsTypes.LOAD_GATEWAY_SUCCESS, { data });
