import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  margin: 0px auto;

  @media only screen and (min-width: 1024px) {
    width: 100%;
  }

  @media only screen and (min-width: 1280px) {
    width: 90%;
  }

  @media only screen and (min-width: 1440px) {
    width: 100%;
  }

  h3 {
    width: 100%;
    text-align: center;
    font-size: 26px;
    color: #999;
    font-weight: bold;
  }
`;

export const Grid = styled.div`
  display: block;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
  margin-bottom: 14px;

  @media only screen and (min-width: 1280px) {
    display: flex;
  }

  @media only screen and (min-width: 1480px) {
    display: flex;
  }

  h4 {
    font-size: 24px;
    color: #999;
    font-weight: bold;
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  background: #fff;
  padding: 8px;
  border-radius: 6px;
`;
