import React, { SelectHTMLAttributes } from 'react';

import { SelectCustom } from './styled';

interface InputProps extends SelectHTMLAttributes<HTMLSelectElement> {
  color?: 'default' | 'primary' | 'secondary';
}

const CustomSelect: React.FC<InputProps> = ({
  color = 'default',
  children,
  ...rest
}) => {
  return (
    <SelectCustom {...rest} color={color}>
      {children}
    </SelectCustom>
  );
};

export default CustomSelect;
