import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';

import { IContract } from '../store/modules/subscriptions/types';
import { IPlans } from '../store/modules/plans/types';
import { IUser } from '../hooks/iTypes';
import { cnpjMask, cpfMask } from './mask';
import { valueToCurrency } from './format';

type ContractTemplateProps = {
  contract: IContract;
  plan: IPlans;
  user: IUser;
};

function replacePlanDetails(plan: IPlans, servicesName: string[]): string {
  const isInfinitePlan = plan.timeLimit === 0;
  if (isInfinitePlan && servicesName.length === 1) {
    return `01 serviço de ${servicesName[0]}`;
  }
  if (isInfinitePlan && servicesName.length === 2) {
    return `01 serviço de ${servicesName[0]} e ${servicesName[1]}`;
  }
  if (isInfinitePlan && servicesName?.length === 3) {
    return `1 (um) serviço de ${servicesName[0]}, ${servicesName[1]}, ${servicesName[2]} e ${servicesName[3]}`;
  }
  if (isInfinitePlan && servicesName?.length >= 4) {
    return `1 (um) serviço de ${servicesName[0]}, ${servicesName[1]}, ${servicesName[2]} e ${servicesName[3]}`;
  }

  if (servicesName?.length === 1) {
    return `3 (três) serviço de ${servicesName[0]}`;
  }

  if (servicesName?.length === 2) {
    if (servicesName[1]?.includes('1º')) {
      return `3 (três) serviço de ${servicesName[0]} e 1 (um) serviço de ${servicesName[1]}`;
    }
    return `3 (três) serviço de ${servicesName[0]} e 3 (três) serviço de ${servicesName[1]}`;
  }
  if (servicesName?.length >= 3) {
    return `3 (três) serviço de ${servicesName[0]}, 3 (três) serviço de ${servicesName[1]}, 1 (um) serviço de ${servicesName[2]} e 1 (um) serviço de ${servicesName[3]}`;
  }
  return '';
}

function replacePlanUniqueService(servicesName: string[]): string {
  const uniqueServices = servicesName.filter(
    (item: string) => !item.includes('1º'),
  );
  return `1 (um) ${uniqueServices.join(', ')}`;
}

export const contractTemplate = ({
  contract,
  plan,
  user,
}: ContractTemplateProps): string => {
  const servicesName = plan.services
    .filter((item: any) => !item.isGift)
    .map((item: any) => item.name);

  const unitCity = user.unit?.city;

  let insertDataContract = contract.content;

  if (plan.unit?.cnpj) {
    insertDataContract = insertDataContract.replace(
      /{{unitFiscalNumber}}/g,
      cnpjMask(plan.unit?.cnpj),
    );
    insertDataContract = insertDataContract.replace(
      /{{unitCnpj}}/g,
      cnpjMask(plan.unit?.cnpj),
    );
  }
  insertDataContract = insertDataContract.replaceAll(
    '{{planDetails}}',
    replacePlanDetails(plan, servicesName),
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{planUniqueService}}',
    replacePlanUniqueService(servicesName),
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{planDetailsOne}}',
    replacePlanUniqueService(servicesName),
  );

  insertDataContract = insertDataContract.replace(
    /{{planService}}/g,
    servicesName.join(' + '),
  );

  insertDataContract = insertDataContract.replace(
    /{{unitCity}}/g,
    unitCity || '',
  );

  insertDataContract = insertDataContract.replace(
    /{{priceValue}}/g,
    valueToCurrency(Number(plan.price)),
  );

  insertDataContract = insertDataContract.replace(
    /{{planTimeLimit}}/g,
    `${plan.timeLimit} meses`,
  );

  insertDataContract = insertDataContract.replace(/{{userName}}/g, user.name);

  insertDataContract = insertDataContract.replace(
    /{{userIdentifier}}/g,
    cpfMask(user.identifier),
  );

  insertDataContract = insertDataContract.replace(
    /{{userIdentifier}}/g,
    format(new Date(), "dd 'de' MMMM 'de' yyyy", {
      locale: ptLocale,
    }),
  );

  const { address }: any = user;
  const cityStateUnit = `${user.unit?.city || ''} - ${user.unit?.state || ''}`;

  if (address) {
    insertDataContract = insertDataContract.replace(
      /{{userAddress}}/g,
      address.address,
    );

    insertDataContract = insertDataContract.replace(
      /{{userCity}}/g,
      address.city,
    );

    insertDataContract = insertDataContract.replace(
      /{{userState}}/g,
      address.state,
    );

    insertDataContract = insertDataContract.replace(
      /{{unitLocalizationCityState}}/g,
      cityStateUnit,
    );

    insertDataContract = insertDataContract.replace(
      /{{unitName}}/g,
      user.unit.name,
    );

    insertDataContract = insertDataContract.replace(
      /{{date}}/g,
      format(new Date(), "dd 'de' MMMM 'de' yyyy", {
        locale: ptLocale,
      }),
    );
  }
  return insertDataContract;
};
