import styled, { css } from 'styled-components';
import { Select } from 'antd';

interface IPropsSelect {
  color: string;
}

export const InputSelect = styled(Select)`
  background: #e1d9d8 !important;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  text-transform: initial;
  color: #686d58;
  height: 50px !important;
  align-items: center;
  text-align: center;

  .ant-select-selector {
    background: #e1d9d8 !important;
    height: 50px;
    border-radius: 8px;
    text-transform: initial;
    color: #686d58;
    height: 50px !important;
    align-items: center;
    text-align: center;
    width: 100%;

    ${(props: any) =>
      props.color === 'white'
        ? css`
            background: #fff !important;
          `
        : css`
            background: #e1d9d8 !important;
          `}
  }

  .ant-select-selection-placeholder {
    margin-top: 5px;
  }

  .ant-select-arrow {
    top: 45%;
  }
`;

export const InputSelectUnit = styled(Select)`
  background: #e1d9d8 !important;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  text-transform: initial;
  color: #686d58;
  height: 50px !important;
  align-items: center;
  text-align: center;

  .ant-select-selector {
    background: #e1d9d8 !important;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
  }

  .ant-select-selection-placeholder {
    margin-top: 5px;
  }

  .ant-select-arrow {
    top: 45%;
  }
`;

export const SelectCustom = styled.select<IPropsSelect>`
  height: 50px;
  width: 100%;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  text-transform: initial;
  color: #686d58;
  height: 50px !important;
  align-items: center;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
  border: 0;

  ${props =>
    props.color === 'default' &&
    css`
      background: #e1d9d8 !important;
    `}

  ${props =>
    props.color === 'secondary' &&
    css`
      background: #fff !important;
    `}
`;
