import styled from 'styled-components';

export const ContainerContract = styled.div`
  overflow-y: scroll;
  height: 350px;
  border: 0.5px solid #ddd;
  padding: 5px 20px;
  background-color: #f2f2f0;
  border-radius: 4px;
  font-size: 12px;

  .contract {
    display: flex;
    flex-direction: column;
    width: 100%;

    #grid {
      display: grid;
      grid-template-columns: 1fr;

      @media only screen and (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }

      @media only screen and (min-width: 1280px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    height: 450px;
    font-size: 15px;
    padding: 5px 30px;
  }

  @media only screen and (min-width: 1280px) {
    height: 450px;
    font-size: 15px;
    padding: 5px 30px;
  }

  h4 {
    width: 100%;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    color: #666;

    @media only screen and (min-width: 1024px) {
      font-size: 18px;
    }

    @media only screen and (min-width: 1280px) {
      font-size: 18px;
    }
  }

  .active {
    color: #00cc00;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  ul {
    margin-left: 20px;

    li {
      text-align: justify;

      & + li {
        margin-top: 12px;
      }
    }
  }

  p {
    text-align: justify;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 3px;
  }
`;
