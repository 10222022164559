import React from 'react';
import { Empty } from 'antd';

import { t } from 'ab18n';

const NotFound: React.FC = () => {
  return (
    <Empty
      image="/static/notFoundSvg.svg"
      description={t('general.notification.pageNotFound')}
    />
  );
};

export default NotFound;
