import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { Button as ButtonStyled } from 'antd';

interface IProps {
  color?: 'default' | 'primary' | 'secondary' | 'warning' | 'danger';
  size?: 'large' | 'small' | 'default';
}

export const Button = styled(ButtonStyled)`
  background: #9ea58a;
`;

export const Container = styled.div`
  display: block;
  .ant-btn-primary {
    background: #9ea58a;
    border-color: #9ea58a;

    :hover,
    :focus {
      background: #859065;
      border-color: #859065;
    }
  }
`;

export const ButtonCustom = styled.button<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 0;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.2s;

  &:disabled {
    background: #666;
  }

  ${props =>
    props.size === 'default' &&
    css`
      height: 50px;
      padding-top: 4px;
      padding-bottom: 4px;
    `}

  ${props =>
    props.size === 'large' &&
    css`
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    `}

  ${props =>
    props.size === 'small' &&
    css`
      height: 40px;
      padding-top: 4px;
      padding-bottom: 4px;
    `}

  div {
    display: flex;
    flex-direction: column;
  }

  small {
    font-size: 12px;
    margin-top: -6px;
  }

  :disabled {
    background: #9999;
    cursor: not-allowed;

    &:hover {
      background: #9999;
    }
  }

  @media only screen and (min-width: 1280px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 1480px) {
    font-size: 18px;
  }

  ${Props =>
    Props.color === 'default' &&
    css`
      background: #667862;

      &:hover {
        background: ${shade(0.3, '#667862')};
      }
    `}

  ${Props =>
    Props.color === 'danger' &&
    css`
      background: #df8350;

      &:hover {
        background: ${shade(0.3, '#df8350')};
      }
    `}

    ${Props =>
    Props.color === 'secondary' &&
    css`
      background: #fff;
      color: #666;

      &:hover {
        background: ${shade(0.2, '#fff')};
      }
    `}

    ${Props =>
    Props.color === 'warning' &&
    css`
      background: #ff9900;

      &:hover {
        background: ${shade(0.3, '#ff9900')};
      }
    `}
`;
