import React from 'react';
import { Modal, Button } from 'antd';

interface IModal {
  open: boolean;
  handleCancel: any;
  title?: string;
  width?: number;
  content: any;
}

export const ModalComponent: React.FC<IModal> = ({
  open,
  handleCancel,
  title,
  width = 900,
  content,
}) => {
  return (
    <Modal
      title={title}
      centered
      width={width}
      visible={open}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Fechar
        </Button>,
      ]}
    >
      {content}
    </Modal>
  );
};
