/**
 * Action types
 */

import { IPlans } from 'store/modules/plans/types';

export enum SubscriptionsTypes {
  LOAD_CONTRACT_REQUEST = '@contract/LOAD_CONTRACT_REQUEST',
  LOAD_CONTRACT_SUCCESS = '@contract/LOAD_CONTRACT_SUCCESS',
  LOAD_SUBSCRIPTIONS_REQUEST = '@subscriptions/LOAD_SUBSCRIPTIONS_REQUEST',
  LOAD_SUBSCRIPTIONS_SUCCESS = '@subscriptions/LOAD_SUBSCRIPTIONS_SUCCESS',
  LOAD_SUBSCRIPTIONS_FAILURE = '@subscriptions/LOAD_SUBSCRIPTIONS_FAILURE',
  CREATE_SUBSCRIPTIONS_REQUEST = '@subscriptions/CREATE_SUBSCRIPTIONS_REQUEST',
  CLEAR_SUBSCRIPTIONS_REQUEST = '@subscriptions/CLEAR_SUBSCRIPTIONS_REQUEST',
  RECEIPT_SUBSCRIPTIONS_SUCCESS = '@subscriptions/RECEIPT_SUBSCRIPTIONS_SUCCESS',
  RECEIPT_SUBSCRIPTIONS_CLEAR = '@subscriptions/RECEIPT_SUBSCRIPTIONS_CLEAR',
  CONFIRMATION_SUBSCRIPTIONS_SUCCESS = '@subscriptions/CONFIRMATION_SUBSCRIPTIONS_SUCCESS',
}

/**
 * Data types
 */

interface ICustomerService {
  id: number;
  service: string;
  status: string;
  when?: Date | null;
}

interface IRevenue {
  id: number;
  status: string;
  paymentDate: string;
  scheduledDate: string;
  amount: number;
}

interface IUnit {
  id: number;
  name: string;
  email: string;
  companyName: string;
  cnpj: string;
}
export interface ISubscriptionsCreate {
  planUser: number;
  customerIdentifier: string;
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
  cardName: string;
  isDigitalTerm: boolean;
}

export interface IContract {
  id: number;
  name: string;
  content: string;
}

export interface ISubscriptionCheck {
  check: boolean;
}

export interface ISubscriptions {
  id: number;
  dueDate: Date;
  endDate: Date | null;
  status: string;
  plan: IPlans;
  revenue: IRevenue[];
  customerService: ICustomerService[];
  unit: IUnit;
  createdAt: Date;
}

/**
 * State types
 */

export interface SubscriptionsState {
  readonly data: ISubscriptions[];
  readonly contract: IContract;
  readonly loading: boolean;
  readonly loadingCreate: boolean;
  readonly error: boolean;
  readonly receipt: boolean;
  readonly confirmation: boolean;
}

/**
 * UseSelector types
 */

interface SubscriptionsUseSelector {
  subscriptions: SubscriptionsState;
}

// Typescript useSelector Dados
export const subscriptionsUseSelector = (state: SubscriptionsUseSelector) =>
  state.subscriptions;
