import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import { stringNumber } from 'utils/mask';

import { useAuth } from 'hooks/auth';
import { useFields } from 'hooks/fields';

import ProfileForm from './form';

const Profiles: React.FC = () => {
  const { handleUpdateAddress } = useAuth();
  const { fieldsProfile, handleZipCode } = useFields();

  const [loading, setLoading] = useState(false);

  const handleZipCodeChange = useCallback(
    (event: any) => {
      const zipcode = stringNumber(event.target.value);

      if (zipcode.length < 8) {
        return;
      }
      handleZipCode(zipcode);
    },
    [handleZipCode],
  );

  const handleSubmit = async (values: any) => {
    setLoading(true);
    await handleUpdateAddress(values);
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Perfil | Portal do Cliente</title>
      </Helmet>

      <ProfileForm
        fields={fieldsProfile}
        handleZipCode={handleZipCodeChange}
        onFinish={handleSubmit}
        loading={loading}
      />
    </>
  );
};

export default Profiles;
