import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use(async config => {
  const { headers } = config;

  const jwt = await localStorage.getItem('@AppAlphaSd:token');
  if (jwt) {
    headers.Authorization = `Bearer ${jwt}`;
  }

  return config;
});

export default api;
