import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';

import { cepMask, cpfMask, phoneMask } from 'utils/mask';

import { useAuth } from 'hooks/auth';

interface FieldsInputs {
  name: any;
  value: any;
}

interface FieldsContextData {
  fieldsProfile: FieldsInputs[];
  handleZipCode(event: string): any;
}

const FieldsContext = createContext<FieldsContextData>({} as FieldsContextData);

const FieldsProvider: React.FC = ({ children }) => {
  const { userMe } = useAuth();
  const [fieldsProfile, setFieldsProfile] = useState<FieldsInputs[]>(
    [] as FieldsInputs[],
  );

  useEffect(() => {
    const customer = [
      { name: ['id'], value: userMe?.id },
      { name: ['user', 'name'], value: userMe?.name },
      {
        name: ['identifier'],
        value: cpfMask(userMe?.identifier),
      },
      { name: ['user', 'phone'], value: phoneMask(userMe?.phone) },
      { name: ['email'], value: userMe?.email },
      { name: ['user', 'linked_avant'], value: userMe?.linked_avant },
      { name: ['user', 'linked_premier'], value: userMe?.linked_premier },
      { name: ['user', 'isBreastfeeding'], value: userMe?.isBreastfeeding },
      { name: ['user', 'isPregnant'], value: userMe?.isPregnant },
      { name: ['user', 'hasAllergies'], value: userMe?.hasAllergies },
      { name: ['user', 'isWhatsapp'], value: userMe?.isWhatsapp },
      {
        name: ['user', 'birthdate'],
        value: userMe?.birthdate ? userMe?.birthdate : '',
      },
    ];

    const unit = [
      { name: ['unit', 'name'], value: userMe?.unit?.name },
      { name: ['unit', 'avant_id'], value: userMe?.unit?.avant_id },
      { name: ['unit', 'email'], value: userMe?.unit?.email },
      { name: ['unit', 'id'], value: userMe?.unit?.id },
    ];

    const address = [
      { name: ['address', 'address'], value: userMe?.address?.address },
      { name: ['address', 'city'], value: userMe?.address?.city },
      { name: ['address', 'country'], value: userMe?.address?.country },
      { name: ['address', 'district'], value: userMe?.address?.district },
      {
        name: ['address', 'neighborhood'],
        value: userMe?.address?.neighborhood,
      },
      {
        name: ['address', 'postal_code'],
        value: cepMask(userMe?.address?.postal_code),
      },
      { name: ['address', 'state'], value: userMe?.address?.state },
      { name: ['address', 'id'], value: userMe?.address?.id },
    ];

    setFieldsProfile([...customer, ...unit, ...address]);
  }, [userMe]);

  const handleZipCode = useCallback((event: string) => {
    fetch(`https://viacep.com.br/ws/${event}/json/`)
      .then(response => response.json())
      .then(data => {
        setFieldsProfile([
          { name: ['address', 'address'], value: data.logradouro },
          { name: ['address', 'neighborhood'], value: data.bairro },
          { name: ['address', 'city'], value: data.localidade },
          { name: ['address', 'state'], value: data.uf },
        ]);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <FieldsContext.Provider
      value={{
        fieldsProfile,
        handleZipCode,
      }}
    >
      {children}
    </FieldsContext.Provider>
  );
};

function useFields(): FieldsContextData {
  const context = useContext(FieldsContext);

  if (!context) {
    throw new Error(' useFields must be used within an fieldsProvider ');
  }
  return context;
}
export { FieldsProvider, useFields };
