import React, { useState } from 'react';

import { t } from 'ab18n';

import api from 'services/api';
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';

import Button from 'components/Button/custom';

import { stringNumber } from 'utils/mask';

import { useAuth } from 'hooks/auth';

import { BackgroundLoading } from 'components/Loading/background';
import message from 'components/Message';
import { Container, Grid, Content } from './styles';

const MyUnit: React.FC = () => {
  const { userMe, handleRemoveUserUnit } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleRemoveUnit = async () => {
    try {
      setLoading(true);
      await api.post('user/remove-unit');
      handleRemoveUserUnit();
    } catch (err: any) {
      message.responseErrors(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Grid>
          <h4>
            {t('pages.myUnit.title')}: {userMe.unit?.name}
          </h4>
          <Button
            type="button"
            style={{ width: 'auto', height: 34 }}
            onClick={handleRemoveUnit}
          >
            <MdEdit /> Trocar Unidade
          </Button>
        </Grid>

        <Content>
          <table className="table-striped">
            <tbody>
              <tr>
                <td width="120">
                  <strong>Email:</strong>
                </td>
                <td>{userMe.unit?.email}</td>
              </tr>
              <tr>
                <td>
                  <strong>Telefone:</strong>
                </td>
                <td>
                  {userMe.unit?.phone && (
                    <>
                      {userMe.unit?.phone} -{' '}
                      <a
                        href={`tel:${stringNumber(userMe.unit?.phone)}`}
                        target="_blank"
                        className="link-phone"
                        rel="noreferrer"
                      >
                        Ligar
                      </a>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Whatsapp:</strong>
                </td>
                <td>
                  {userMe.unit?.whatsApp && (
                    <>
                      {userMe.unit?.whatsApp} -
                      <a
                        href={`https://api.whatsapp.com/send?phone=55${stringNumber(
                          userMe.unit?.whatsApp,
                        )}`}
                        target="_blank"
                        className="link-whatsapp"
                        rel="noreferrer"
                      >
                        Whatsapp
                      </a>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Rede Social:</strong>
                </td>
                <td>
                  {userMe.unit?.instagram && (
                    <a
                      href={userMe.unit?.instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaInstagram size={28} />
                    </a>
                  )}
                  {userMe.unit?.facebook && (
                    <a
                      href={userMe.unit?.facebook}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaFacebookF size={28} />
                    </a>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Endereço:</strong>
                </td>
                <td>
                  {userMe.unit?.address} - {userMe.unit?.neighborhood} -{' '}
                  {userMe.unit?.city} - {userMe.unit?.state}
                </td>
              </tr>
            </tbody>
          </table>
        </Content>
      </Container>

      <BackgroundLoading loading={loading} />
    </>
  );
};

export default MyUnit;
