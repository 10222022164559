import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { History } from 'history';

import Home from 'pages/Home';

import Login from 'pages/Login';
import Register from 'pages/Register';

import Profile from 'pages/Profiles';

import Plans from 'pages/Plans';

import MyUnit from 'pages/MyUnit';

import Buy from 'pages/Buy';
import { ThankYou } from 'pages/Buy/thankyou';

import CreateSchedule from 'pages/Schedules/createSchedule';

import NotFound from 'pages/NotFound';
import Route from './Route';

interface AppProps {
  history: History;
}

const Routes: React.FC<AppProps> = () => (
  <Switch>
    <Route path="/" exact component={Home} isPrivate />

    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />

    <Route path="/buy" component={Buy} isPrivate />
    <Route path="/thankyou" component={ThankYou} isPrivate />

    <Route path="/profile" component={Profile} isPrivate />

    <Route path="/myplan" component={Plans} isPrivate />

    <Route path="/myunit" component={MyUnit} isPrivate />

    <Route path="/schedule/create" component={CreateSchedule} isPrivate />

    <Route path="/404" component={NotFound} />

    <Redirect to="/404" />
  </Switch>
);

export default Routes;
