import React from 'react';
import { LayoutInitial } from './styled';
import SiderLayout from './siderLayout';

const baseLayout: React.FC = ({ children }) => (
  <LayoutInitial>
    <SiderLayout>{children}</SiderLayout>
  </LayoutInitial>
);

export default baseLayout;
