import React, { useCallback, useState } from 'react';

import { Alert, Col, Form, Row, Skeleton, Switch } from 'antd';

import { Country, IStates } from 'utils/locales';

import { t } from 'ab18n';

import { stringNumber, cepUnMask } from 'utils/mask';

import ButtonCustom from 'components/Button/custom';
import Input from 'components/Input';
import SelectCustom from 'components/Select/custom';
import message from 'components/Message';

import { useAuth } from 'hooks/auth';
import { useFields } from 'hooks/fields';

import api from 'services/api';

import { ContainerAddress } from './styled';

const Address: React.FC = () => {
  const { userMe: data, handleUpdateUser } = useAuth();
  const { fieldsProfile, handleZipCode } = useFields();

  const [states, setStates] = useState<IStates[]>([]);
  const [loading, setLoading] = useState(false);

  if (data?.address?.country && states.length === 0) {
    const result = Country.find(
      value => value.abbreviation === data.address?.country,
    );
    setStates(result?.state || []);
  }

  const handleChangeCountry = (event: any) => {
    const result = Country.find(
      value => value.abbreviation === event.target.value,
    );
    setStates(result?.state || []);
  };

  const handleZipCodeChange = useCallback(
    (event: any) => {
      const zipcode = stringNumber(event.target.value);

      if (zipcode.length < 8) {
        return;
      }
      handleZipCode(zipcode);
    },
    [handleZipCode],
  );

  const onFinish = useCallback(
    async (value: any) => {
      const { user, address } = value;

      try {
        setLoading(true);
        message.loading();
        const response = await api.put('user/link', {
          user,
          address: {
            ...address,
            postal_code: cepUnMask(address.postal_code),
          },
        });

        handleUpdateUser(response.data.data);
        setLoading(false);

        message.success(response.data.message, 5);
      } catch (err: any) {
        if (err.response.data.message) {
          message.warning(err.response.data.message, 5);
        } else {
          message.error(err.response.statusText, 5);
        }

        setLoading(false);
      }
    },
    [handleUpdateUser],
  );

  return data ? (
    <ContainerAddress>
      {!data?.address ? (
        <Alert
          message="Cadastro incompleto"
          description="Necessário cadastrar um endereço para concluir seu pedido"
          type="warning"
          style={{ marginBottom: 10 }}
          showIcon
        />
      ) : null}
      {fieldsProfile.length && (
        <Form fields={fieldsProfile} onFinish={onFinish} id="form-address">
          <div className="grid-form-two">
            <Form.Item
              name={['address', 'postal_code']}
              rules={[
                { required: true, message: t('profile.forms.fillZipCode') },
              ]}
            >
              <Input
                placeholder="CEP"
                mask="99999-999"
                onKeyUp={handleZipCodeChange}
              />
            </Form.Item>
            <Form.Item
              name={['address', 'address']}
              rules={[
                {
                  required: true,
                  message: t('profile.forms.fillAddress'),
                },
              ]}
            >
              <Input placeholder="Endereco" />
            </Form.Item>
          </div>

          <div className="grid-form">
            <Form.Item
              name={['address', 'neighborhood']}
              rules={[
                { required: true, message: t('profile.forms.fillEmail') },
              ]}
            >
              <Input placeholder="Bairro" />
            </Form.Item>
            <Form.Item
              name={['address', 'city']}
              rules={[{ required: true, message: t('profile.forms.fillCity') }]}
            >
              <Input placeholder="Cidade" />
            </Form.Item>
            <Form.Item
              name={['address', 'country']}
              rules={[
                { required: true, message: t('profile.forms.fillCountry') },
              ]}
            >
              <SelectCustom placeholder="País" onChange={handleChangeCountry}>
                <option value="">...</option>
                {Country.map(value => (
                  <option key={value.abbreviation} value={value.abbreviation}>
                    {value.name}
                  </option>
                ))}
              </SelectCustom>
            </Form.Item>
            <Form.Item
              name={['address', 'state']}
              rules={[
                { required: true, message: t('profile.forms.fillState') },
              ]}
            >
              <SelectCustom
                placeholder="Estado"
                disabled={!(states.length > 0)}
              >
                {states.map(value => (
                  <option key={value.UF} value={value.UF}>
                    {value.name}
                  </option>
                ))}
              </SelectCustom>
            </Form.Item>
          </div>
          <h2>Outros</h2>
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 8 }} lg={{ span: 8 }}>
              <Form.Item
                name={['user', 'isBreastfeeding']}
                label="É lactante?"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col xs={{ span: 8 }} lg={{ span: 8 }}>
              <Form.Item
                name={['user', 'hasAllergies']}
                label="É alérgica(o)?"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col xs={{ span: 8 }} lg={{ span: 8 }}>
              <Form.Item
                name={['user', 'isPregnant']}
                label="É gestante?"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <ButtonCustom type="submit" loading={loading}>
                {data?.linked_avant
                  ? t('general.update')
                  : t('general.register')}
              </ButtonCustom>
            </Col>
          </Row>
        </Form>
      )}
    </ContainerAddress>
  ) : (
    <Skeleton active />
  );
};

export default Address;
