import { Reducer } from 'redux';
import { UnitState, UnitsTypes, IUnit, IGateWay } from './types';

const INITIAL_STATE: UnitState = {
  data: [],
  gateway: {} as IGateWay,
  unit: {} as IUnit,
  error: false,
  loading: false,
  loadLink: false,
};

const reducer: Reducer<UnitState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UnitsTypes.LOAD_UNITS_REQUEST:
      return { ...state, loading: true };
    case UnitsTypes.LOAD_UNITS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case UnitsTypes.LOAD_UNITS_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    case UnitsTypes.SHOW_UNIT_REQUEST:
      return { ...state, loading: true };
    case UnitsTypes.SHOW_UNIT_SUCCESS:
      return { ...state, loading: false, unit: action.payload.data };
    case UnitsTypes.LINK_UNIT_LOAD:
      return {
        ...state,
        loadLink: false,
        error: false,
      };
    case UnitsTypes.LINK_UNIT_REQUEST:
      return { ...state, loadLink: true };
    case UnitsTypes.LOAD_GATEWAY_REQUEST:
      return { ...state };
    case UnitsTypes.LOAD_GATEWAY_SUCCESS:
      return { ...state, gateway: action.payload.data };
    default:
      return state;
  }
};

export default reducer;
