interface ILocalStorage {
  TOKEN: string;
  UNIT_ID: string;
  PLAN: string;
  ADDRESS_EXIST: string;
}

export default {
  TOKEN: '@AppAlphaSd:token',
  UNIT_ID: '@AppAlphaSd:unit_id',
  PLAN: '@AppAlphaSd:plan',
  ADDRESS_EXIST: '@AppAlphaSd:address_exists',
} as ILocalStorage;
