/**
 * Action types
 */

export enum UnitsTypes {
  LOAD_UNITS_REQUEST = '@units/LOAD_UNITS_REQUEST',
  LOAD_UNITS_SUCCESS = '@units/LOAD_UNITS_SUCCESS',
  LOAD_UNITS_FAILURE = '@units/LOAD_UNITS_FAILURE',
  SHOW_UNIT_REQUEST = '@units/SHOW_UNIT_REQUEST',
  SHOW_UNIT_SUCCESS = '@units/SHOW_UNIT_SUCCESS',
  LINK_UNIT_REQUEST = '@units/LINK_UNIT_REQUEST',
  LINK_UNIT_LOAD = '@units/LINK_UNIT_LOAD',
  LOAD_GATEWAY_REQUEST = '@units/LOAD_GATEWAY_REQUEST',
  LOAD_GATEWAY_SUCCESS = '@units/LOAD_GATEWAY_SUCCESS',
}

/**
 * Data types
 */

interface IUnitSocialMedia {
  facebook: string;
  instagram: string;
}

interface IOpeningHours {
  begin: any;
  end: any;
}

export interface IGateWay {
  gatewayId: number;
  gateway: string;
  paymentProfileUri: string;
  baseUri: string;
  vindiPublicKey: string;
}

export interface IUnit {
  id: number;
  avant_id: number;
  fiscalNumber: string;
  name: string;
  email: string;
  phone: string;
  showWhatsApp: boolean;
  whatsApp: string;
  socialMedia?: IUnitSocialMedia;
  address: string;
  neighborhood: string;
  city: string;
  state: string;
  region: string;
  country: string;
  countryAcronym: string;
  postal_code: any;
  openingHours: IOpeningHours[];
}

export interface ILinkUnit {
  email: string;
  id: string;
}

export interface IShowUnit {
  email: string;
}

/**
 * State types
 */

export interface UnitState {
  readonly data: IUnit[];
  readonly gateway: IGateWay;
  readonly unit: IUnit;
  readonly loading: boolean;
  readonly error: boolean;
  readonly loadLink: boolean;
}

/**
 * UseSelector types
 */

interface UnitUseSelector {
  units: UnitState;
}

// Typescript useSelector Dados
export const unitsUseSelector = (state: UnitUseSelector) => state.units;
