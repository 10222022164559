import styled, { css } from 'styled-components';

interface IPropsParagraph {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .bg-woman {
    display: block;
    position: absolute;
    width: 96%;
    bottom: 0;
    left: 55%;
    transform: translateX(-50%);

    img {
      height: 500px;
      @media only screen and (max-width: 1420px) {
        height: 300px;
        margin-left: 10%;
      }
    }

    @media only screen and (min-width: 1024px) {
      width: 580px;
    }

    @media only screen and (min-width: 1280px) {
      width: 620px;
    }

    @media only screen and (min-width: 1420px) {
      width: 670px;
    }
  }
`;

export const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 320px;
  background: #e6e6e6 url('/static/images/BG-crop.jpg') no-repeat center;
  background-size: cover;

  @media only screen and (min-width: 1280px) {
    height: 500px;
    background: #e6e6e6 url('/static/images/BG-crop.jpg') no-repeat;
    background-size: cover;
  }

  @media only screen and (min-width: 1480px) {
    height: 550px;
    background: #e6e6e6 url('/static/images/BG-crop.jpg') no-repeat;
    background-size: cover;
  }

  .description-welcome-mobile {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 100%;
    padding-bottom: 26px;
    padding-top: 14px;
    transform: translateX(-50%);
    z-index: 99;
    text-align: center;
    color: #fff;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.02)
    );

    h4 {
      color: #fff;
      font-size: 20px;
      width: 180px;
      margin: 0px auto;
      line-height: 24px;
      font-weight: 100;

      @media only screen and (min-width: 1280px) {
        font-size: 24px;
      }

      @media only screen and (min-width: 1480px) {
        font-size: 24px;
      }
    }
  }

  .container-header {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-top: 10px;

    .logo-mobile {
      width: 100%;
      text-align: center;
    }

    img {
      width: 180px;
      margin-bottom: 12px;
    }

    @media only screen and (min-width: 1280px) {
      padding-top: 200px;

      img {
        width: 300px;
        margin-bottom: 12px;
      }
    }

    @media only screen and (min-width: 1480px) {
      padding-top: 200px;

      img {
        width: 300px;
        margin-bottom: 12px;
      }
    }

    .description-welcome {
      width: 375px;
      color: #666666;

      p {
        font-size: 22px;
        line-height: 26px;
      }

      h4 {
        font-size: 26px;
        color: #666666;

        strong {
          font-weight: bold;
        }
      }
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 90%;
  height: 100%;
  margin: 0px auto;
  position: relative;
  z-index: 8;

  max-width: auto;

  @media only screen and (min-width: 1280px) {
    max-width: auto;
  }

  @media only screen and (min-width: 1480px) {
    max-width: 1280px;
  }
`;

export const GridForm = styled.div`
  position: relative;
  bottom: 0;
  right: 0;
  display: block;
  border-radius: 16px;
  background: #fff;
  width: 100%;
  padding: 22px;
  z-index: 99;
  box-shadow: 2px 3px 8px 0px rgb(154 160 185 / 60%),
    0px 0px 0px rgb(166 173 201 / 52%);

  .grid-send {
    margin-bottom: 14px;
  }

  .btn-reset {
    border: 0;
    background: none;

    &:hover {
      color: #ccc;
    }
  }

  @media only screen and (min-width: 1280px) {
    position: absolute;
    width: 420px;
    bottom: -180px;
    padding: 110px 30px;
    right: 0;
  }

  @media only screen and (min-width: 1480px) {
    position: absolute;
    width: 420px;
    bottom: -180px;
    padding: 110px 30px;
    right: 0;
  }

  form {
    z-index: 999;
  }

  button {
    z-index: 99;
  }

  button + button {
    margin-top: 8px;
  }

  .font-large {
    font-size: 22px;
    margin-bottom: 26px;
  }

  .login-fiscalnumber {
    text-align: center;

    font-size: 16px;
    color: #667862;
    line-height: 16px;
  }

  .login-password {
    font-size: 18px;
    color: #667862;
    line-height: 18px;
  }

  @media only screen and (min-width: 1024px) {
    .font-large {
      font-size: 26px;
      margin-bottom: 32px;
    }

    .login-fiscalnumber {
      font-size: 22px;
      line-height: 22px;
    }

    .login-password {
      font-size: 26px;
      line-height: 26px;
    }
  }

  @media only screen and (min-width: 1280px) {
    .font-large {
      font-size: 26px;
      margin-bottom: 32px;
    }

    .login-fiscalnumber {
      font-size: 22px;
      line-height: 22px;
    }

    .login-password {
      font-size: 26px;
      line-height: 26px;
    }
  }

  @media only screen and (min-width: 1480px) {
    .font-large {
      font-size: 26px;
      margin-bottom: 32px;
    }

    .login-fiscalnumber {
      font-size: 22px;
      line-height: 22px;
    }

    .login-password {
      font-size: 26px;
      line-height: 26px;
    }
  }

  .description {
    margin-bottom: 4px;

    p {
      strong {
        color: #667862;
        font-weight: 800;
      }
    }
  }
`;

export const Footer = styled.div`
  display: block;
  position: relative;
  height: 100%;
  padding-top: 14px;

  .bg-bubble {
    width: 100%;
    height: 180px;
    background: url('/static/images/bg-bubble.png') no-repeat right top;

    img {
      width: 100%;
    }
  }

  .form-mobile {
    display: block;
    padding-left: 24px;
    padding-right: 24px;
  }

  .description-mobile {
    position: relative;
    width: 86%;
    background: #445e3e;
    color: #fff;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    padding: 14px;
    z-index: 99;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      width: 100%;
      cursor: pointer;
    }

    h4 {
      font-size: 16px;
      font-weight: 800;
      color: #fff;
      line-height: 18px;
    }

    ul {
      list-style: none;
      font-size: 12px;
      line-height: 14px;
      font-style: italic;
      font-weight: 100;

      li + li {
        margin-top: 8px;
      }
    }
  }

  .description {
    padding-top: 12px;
    color: #666666;
    display: block;
    width: 90%;
    height: 100%;
    margin: 0px auto;

    @media only screen and (min-width: 1280px) {
      max-width: auto;
    }

    @media only screen and (min-width: 1480px) {
      max-width: 1280px;
    }

    h4 {
      font-size: 22px;
      font-weight: 800;
      color: #666666;
    }

    ul {
      margin-left: 13px;

      li {
        font-style: italic;
        font-size: 18px;
      }
    }
  }
`;

export const Paragraph = styled.p<IPropsParagraph>`
  border-top: 1px dotted #6f9a65;
  padding-top: 6px;

  ${props =>
    props.active
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;
