import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 20px;
  display: block;
  width: 100%;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  padding: 4px 7px;
  background: #fff;
  align-items: center;
  color: #666;
  justify-content: space-between;
  border-bottom: 1px solid #bbb;

  button {
    border: 0;
    background: none;
    svg {
      color: #666;
      font-size: 22px;
    }
  }
`;

export const Days = styled.ul`
  display: grid;
  width: 100%;
  /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
  grid-template-columns: repeat(7, 1fr);
  /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
  grid-auto-rows: auto;
  /* margem interna que afasta os elementos da borda do grid */
  text-align: center;
  li {
    display: block;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #bbb;
    font-size: 10px;
    font-weight: bold;

    @media only screen and (min-width: 1280px) {
      font-size: 12px;
    }

    @media only screen and (min-width: 1480px) {
      font-size: 12px;
    }

    & + li {
      border-left: 1px solid #bbb;
    }
  }
`;

export const Cells = styled.div`
  display: grid;
  width: 100%;
  height: 74%;
  /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
  grid-template-columns: repeat(7, 1fr);
  /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
  grid-auto-rows: auto;
  /* margem interna que afasta os elementos da borda do grid */
  text-align: center;
  color: #fff;

  .calendar-cell--selected {
    background: #667862;
    color: #fff;
  }
  .calendar-cell--sunday {
    color: #ff5c26;
  }
  .calendar-cell--today {
    background: #667862;
    color: #fff;
  }
  .calendar-cell--disabled {
    background: #eee;
    color: #666;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: none;
  background: none;
  border-bottom: 1px solid #bbb;
  border-left: 1px solid #bbb;
  height: 42px;
  position: relative;
  transition: background-color 0.2s;
  color: #4d4d4d;
  font-size: 13px;

  @media only screen and (min-width: 1280px) {
    font-size: 15px;
  }

  @media only screen and (min-width: 1480px) {
    font-size: 15px;
  }

  &:hover {
    background: #667862;
    color: #fff;
  }

  &:disabled {
    cursor: no-drop;
    color: #a6a6a6;

    &:hover {
      background: none;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);

  span + span {
    margin-left: 5px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  flex: 1;
  div {
    display: flex;
    align-items: center;
    font-size: 12px;
    .reminder {
      width: 8px;
      height: 8px;
      background: #0080ff;
      border-radius: 4px;
      margin-right: 4px;
    }
    & + div {
      margin-left: 8px;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px;
    flex: 1;
    p {
      display: flex;
      width: 70%;
    }
    small {
      display: flex;
      align-items: center;
      padding-left: 6px;
      padding-right: 6px;
    }
    .reminder {
      font-size: 11px;
      padding: 3px 4px;
      background: #0080ff;
      color: #fff;
    }
  }
`;
