import React from 'react';
import ReactInputMask from 'react-input-mask';

import { InputText, InputPassword, InputDate } from './styled';

const InputCustom: React.FC<any> = ({
  prefix,
  mask = '',
  color = '',
  textTransform = 'initial',
  ...rest
}) => {
  if (rest.type === 'password') {
    return <InputPassword prefix={prefix} {...rest} color={color} />;
  }
  if (mask) {
    return (
      <ReactInputMask mask={mask} maskChar={null} {...rest} color={color}>
        {(rest: any) => <InputText prefix={prefix} {...rest} />}
      </ReactInputMask>
    );
  }
  if (rest.type === 'date') {
    return <InputDate {...rest} color={color} />;
  }
  return (
    <InputText prefix={prefix} theme={textTransform} {...rest} color={color} />
  );
};

export default InputCustom;
