import { message } from 'antd';

const timeDefault = 3;

interface IResponseErrors {
  response: { data: { errors?: any; message?: string } };
}

const success = (text: string, time = timeDefault, key = 1) => {
  message.success({ content: text, key, duration: time });
};

const error = (text: string, time = timeDefault, key = 1) => {
  message.error({ content: text, key, duration: time });
};

const warning = (text: string, time = timeDefault, key = 1) => {
  message.warning({ content: text, key, duration: time });
};

const info = (text: string, time = timeDefault, key = 1) => {
  message.info({ content: text, key, duration: time });
};

const loading = (text = 'Carregando...', key = 1) => {
  message.loading({ content: text, key });
};

const responseErrors = (responseError: IResponseErrors) => {
  if (!responseError.response) {
    error('Erro ao conectar com servidor', 5);
    return null;
  }

  const {
    response: {
      data: { errors, message: messageError },
    },
  } = responseError;

  if (!errors && messageError) {
    error(messageError, 5);
    return null;
  }

  let customErrors = [];

  if (Array.isArray(errors)) {
    customErrors = errors;
  } else {
    customErrors = Object.values(errors);
  }

  customErrors.map(async (item: string, index) => {
    await message.error({ content: item, key: index + 1, duration: 5 });
  });
  return null;
};

const Message = {
  success,
  error,
  warning,
  loading,
  info,
  responseErrors,
};

export default Message;
