import {
  addYears,
  getYear,
  getMonth,
  getDate,
  addMinutes,
  setHours,
  subMinutes,
  isPast,
  format,
} from 'date-fns';

interface IHours {
  date: any;
  hours: number;
  minutes: number;
  sumQtd?: number;
}

export const months: any = [
  { month: '01', name: 'Janeiro' },
  { month: '02', name: 'Fevereiro' },
  { month: '03', name: 'Março' },
  { month: '04', name: 'Abril' },
  { month: '05', name: 'Maio' },
  { month: '06', name: 'Junho' },
  { month: '07', name: 'Julho' },
  { month: '08', name: 'Agosto' },
  { month: '09', name: 'Setembro' },
  { month: '10', name: 'Outubro' },
  { month: '11', name: 'Novembro' },
  { month: '12', name: 'Dezembro' },
];

export const years: any = (year: number) => {
  const addYear = getYear(addYears(new Date(), year));
  const years = [];

  let i = Number(getYear(new Date()));

  for (; i < addYear; i++) {
    years.push(i);
  }

  return years;
};

export const hours = ({ date, hours, minutes, sumQtd = 20 }: IHours) => {
  const year = getYear(new Date(date));
  const month = getMonth(new Date(date));
  const day = getDate(new Date(date));

  let hoursNine = subMinutes(setHours(new Date(year, month, day), hours), 30);
  const getHours = [];

  let i = 0;
  let hoursgetNine = '';

  for (; i < sumQtd; i++) {
    hoursNine = addMinutes(hoursNine, minutes);
    hoursgetNine = format(hoursNine, 'HH:mm');
    getHours.push({
      hours: hoursgetNine,
      isPast: isPast(hoursNine),
    });
  }

  return getHours;
};
