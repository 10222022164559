import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import moment from 'moment';

import ptBR from 'antd/lib/locale/pt_BR';
import * as ab18n from 'ab18n';
import locales from 'i18n/locale';
import { ConfigProvider } from 'antd';

import GlobalStyle from './styles/global';
import { Body } from './styles/styles';

import history from './services/history';

import store from './store';

import Routes from './routes';
import Layout from './components/Layout';

import AppProvider from './hooks';

ab18n.onLocaleChange((data: any) => moment.locale(data.locale));
ab18n.config(locales);
ab18n.set('pt-BR');

const App: React.FC = () => (
  <>
    <Body>
      <Router>
        <ConfigProvider locale={ptBR}>
          <AppProvider>
            <Provider store={store}>
              <Layout>
                <Routes history={history} />
              </Layout>
            </Provider>
          </AppProvider>
        </ConfigProvider>
      </Router>
    </Body>
    <GlobalStyle />
  </>
);

export default App;
