/**
 * Action types
 */

export enum PlansTypes {
  LOAD_PLANS_REQUEST = '@plans/LOAD_PLANS_REQUEST',
  LOAD_PLANS_SUCCESS = '@plans/LOAD_PLANS_SUCCESS',
  LOAD_PLANS_FAILURE = '@plans/LOAD_PLANS_FAILURE',
  SELECT_PLANS_REQUEST = '@plans/SELECT_PLANS_REQUEST',
  FIND_PLANS_REQUEST = '@plans/FIND_PLANS_REQUEST',
  VALIDATE_PLANS_REQUEST = '@plans/VALIDATE_PLANS_REQUEST',
  CLEAR_SELECTED_PLANS_REQUEST = '@plans/CLEAR_SELECTED_PLANS_REQUEST',
  RESET_PLANS_REQUEST = '@plans/RESET_PLANS_REQUEST',
}

/**
 * Data types
 */

interface IPlansService {
  id: number;
  name: string;
  quantity: number;
  isGift: boolean;
}

interface IUnit {
  id: number;
  name: string;
  email: string;
  companyName: string;
  cnpj: string;
}

export interface IPlans {
  id: number;
  name: string;
  timeLimit: number;
  discount: number;
  original_price: number;
  price: number;
  image_url?: string;
  isRecommended: boolean;
  isFirstAttendance: boolean;
  description: string;
  services?: IPlansService | any;
  unit: IUnit;
}

export interface IValidateTermPlan {
  check: boolean;
}

export interface IPlansIsFirstAttandance {
  is_first_attandance: boolean;
}

/**
 * State types
 */

export interface PlanState {
  readonly data: IPlans[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly selectedPlan: IPlans | any;
  readonly validateTerm: boolean;
}

/**
 * UseSelector types
 */

interface PlanUseSelector {
  plans: PlanState;
}

// Typescript useSelector Dados
export const plansUseSelector = (state: PlanUseSelector) => state.plans;
