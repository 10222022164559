import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { unitsShowRequest } from 'store/modules/units/action';
import { unitsUseSelector } from 'store/modules/units/types';

import { CalendarProvider } from 'hooks/calendar';
import { useAuth } from 'hooks/auth';

import Create from './create';

const week = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const CreateSchedule: React.FC = () => {
  const dispatch = useDispatch();
  const { unit } = useSelector(unitsUseSelector);
  const { userMe } = useAuth();

  useEffect(() => {
    if (Object.keys(userMe).length > 0) {
      dispatch(unitsShowRequest({ email: userMe.unit.email }));
    }
  }, [userMe]); //eslint-disable-line

  let openingHours: any[] = [];

  if (Object.keys(unit).length > 0 && unit.openingHours) {
    openingHours = unit.openingHours.map((item, index) => ({
      type: 'closed',
      closed: !!item.begin,
      week: week[index],
    }));
  }

  return (
    <div
      id="verifyOpeningHours"
      className={`openingHours-${!!unit.openingHours}`}
    >
      <CalendarProvider events={openingHours} weekStartsOn={1}>
        <Create />
      </CalendarProvider>
    </div>
  );
};

export default CreateSchedule;
