import styled, { css } from 'styled-components';

import { isIOS } from 'react-device-detect';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;

    h2 {
      color: #405e39;
      font-weight: bold;
      font-size: 3.5vh;
      text-align: center;

      @media only screen and (min-width: 1280px) {
        font-size: 4.6vh;
      }

      @media only screen and (min-width: 1440px) {
        font-size: 6.5vh;
      }

      @media only screen and (min-width: 1600px) {
        font-size: 8vh;
      }
    }

    .price-description {
      display: block;
      font-size: 1.6vh;
      padding-left: 22px;
      padding-right: 22px;
      text-align: center;
      color: #6f864e;

      span {
        font-size: 1.8vh;
      }

      .btn-buy {
        font-size: 18px;
      }

      @media only screen and (min-width: 1280px) {
        font-size: 2.2vh;

        span {
          font-size: 2.4vh;
        }

        .btn-buy {
          font-size: 20px;
        }
      }

      @media only screen and (min-width: 1440px) {
        font-size: 3vh;

        span {
          font-size: 3.2vh;
        }

        .btn-buy {
          font-size: 26px;
        }
      }

      @media only screen and (min-width: 1600px) {
        font-size: 3.55vh;

        span {
          font-size: 3.75vh;
        }

        .btn-buy {
          font-size: 28px;
        }
      }
    }

    .description {
      padding-left: 22px;
      padding-right: 22px;
      text-align: center;
      color: #405e39;

      p {
        font-size: 1.6vh;

        @media only screen and (min-width: 1280px) {
          font-size: 2.2vh;
        }

        @media only screen and (min-width: 1440px) {
          font-size: 3vh;
        }

        @media only screen and (min-width: 1600px) {
          font-size: 3.55vh;
        }
      }
    }

    .description-first {
      padding-left: 22px;
      padding-right: 22px;
      text-align: center;
      color: #405e39;

      p {
        font-size: 2vh;

        @media only screen and (min-width: 1280px) {
          font-size: 2.6vh;
        }

        @media only screen and (min-width: 1440px) {
          font-size: 3vh;
        }

        @media only screen and (min-width: 1600px) {
          font-size: 3.6vh;
        }
      }

      small {
        font-size: 1.2vh;
        margin-bottom: 12px;

        @media only screen and (min-width: 1280px) {
          font-size: 1.5vh;
        }

        @media only screen and (min-width: 1440px) {
          font-size: 1.5vh;
        }

        @media only screen and (min-width: 1600px) {
          font-size: 2vh;
        }
      }
    }
  }

  h3 {
    text-align: center;
    font-size: 28px;
    color: #666;
    font-weight: bold;

    @media only screen and (min-width: 1280px) {
      font-size: 30px;
    }

    @media only screen and (min-width: 1440px) {
      font-size: 33px;
    }

    @media only screen and (min-width: 1600px) {
      font-size: 36px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    padding: 12px;
    border-radius: 3px;

    @media only screen and (min-width: 1280px) {
      flex-direction: row;
      width: 80%;
    }

    @media only screen and (min-width: 1440px) {
      flex-direction: row;
      width: 90%;
    }

    @media only screen and (min-width: 1600px) {
      flex-direction: row;
      width: 100%;
    }

    .img {
      img {
        width: 100%;
      }

      @media only screen and (min-width: 1280px) {
        img {
          width: 340px;
        }
      }

      @media only screen and (min-width: 1440px) {
        img {
          width: auto;
        }
      }
    }
  }

  .subscription {
    display: flex;
    width: 100%;

    .img-subscription {
      display: none;

      @media only screen and (min-width: 1024px) {
        display: flex;
      }

      @media only screen and (min-width: 1280px) {
        display: flex;
      }
    }

    .content-subscription {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding-left: 22px;
      padding-right: 22px;
      padding-top: 28px;
      padding-bottom: 20px;
      text-align: center;

      @media only screen and (min-width: 1024px) {
        padding-bottom: 0;
      }

      @media only screen and (min-width: 1280px) {
        padding-bottom: 0;
      }

      .title {
        font-size: 22px;
        font-weight: bold;
        color: #6d8855;

        @media only screen and (min-width: 1024px) {
          font-size: 34px;
        }

        @media only screen and (min-width: 1280px) {
          font-size: 36px;
        }
      }

      .subtitle {
        font-size: 19px;
        font-weight: bold;

        @media only screen and (min-width: 1024px) {
          font-size: 30px;
        }

        @media only screen and (min-width: 1280px) {
          font-size: 32px;
        }
      }
    }
  }
`;

export const Container = styled.div`
  display: block;
  width: 100%;
  margin: 0px auto;

  .title-step {
    font-size: 24px;

    @media only screen and (min-width: 1024px) {
      font-size: 34px;
    }

    @media only screen and (min-width: 1280px) {
      font-size: 34px;
    }

    @media only screen and (min-width: 1440px) {
      font-size: 34px;
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
  }

  @media only screen and (min-width: 1280px) {
    width: 90%;
  }

  @media only screen and (min-width: 1440px) {
    width: 100%;
  }

  h2 {
    width: 100%;
    text-align: center;
    font-size: 22px;

    @media only screen and (min-width: 1024px) {
      font-size: 28px;
    }

    @media only screen and (min-width: 1280px) {
      font-size: 28px;
    }
  }
`;

export const SignContent = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  padding: 2px;
  width: 100%;
  max-width: 1100px;
  box-shadow: 3px 3px 5px 0px rgb(154 160 185 / 91%),
    -1px -1px 6px rgb(166 173 201 / 52%);
  margin: 0px auto;

  .title-next-prev {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  @media only screen and (min-width: 1024px) {
    padding: 14px;
  }

  @media only screen and (min-width: 1280px) {
    padding: 14px;
  }

  .prev {
    font-size: 55px;
    cursor: pointer;

    @media only screen and (min-width: 1024px) {
      left: 12px;
      font-size: 65px;
    }

    @media only screen and (min-width: 1280px) {
      left: 12px;
      font-size: 65px;
    }
  }

  .next {
    font-size: 55px;
    cursor: pointer;

    @media only screen and (min-width: 1024px) {
      right: 12px;
      font-size: 65px;
    }

    @media only screen and (min-width: 1280px) {
      right: 12px;
      font-size: 65px;
    }
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #667862;
  }

  .ant-steps-item-title::after {
    position: absolute;
    top: 10px;
    left: 0;
    display: block;
    width: 9999px;
    height: 6px;
    background: #f0f0f0;
    content: '';
  }

  .footer-step {
    margin-top: 40px;
    margin-bottom: 12px;
    border-top: 1px solid dotted #999;
  }

  ${isIOS
    ? css`
        margin-bottom: 65px;
      `
    : css`
        margin-bottom: 20px;
      `}

  @media only screen and (min-width: 481px) {
    ${isIOS
      ? css`
          margin-bottom: 65px;
        `
      : css`
          margin-bottom: 20px;
        `}
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 1280px) {
    margin-bottom: 0px;
  }

  .steps-action {
    display: flex;
    margin: 20px 0px;
  }

  .content {
    margin-top: 25px;
  }

  .ant-steps-item-custom {
    svg {
      color: #798264;
    }
  }

  .ant-steps-item-disabled {
    svg {
      color: #ccc;
    }
  }
`;

export const ContainerStep = styled.div`
  display: block;
  padding-top: 12px;
  padding-left: 14px;
  padding-right: 14px;

  @media only screen and (min-width: 1024px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media only screen and (min-width: 1280px) {
    padding-left: 48px;
    padding-right: 48px;
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 76vh;
  align-items: center;
  justify-content: center;

  p {
    font-size: 13px;
    margin-top: 4px;
  }
`;
