import React, { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';
import { MdStore } from 'react-icons/md';

import { t } from 'ab18n';

import { isMobile } from 'react-device-detect';

import { Link, useLocation } from 'react-router-dom';

import {
  validateTermPlanRequest,
  plansClearSelectedRequest,
  planResetRequest,
} from 'store/modules/plans/action';

import {
  subscriptionsClearRequest,
  subscriptionReceiptClear,
  subscriptionConfirmationSuccess,
} from 'store/modules/subscriptions/action';

import { useAuth } from 'hooks/auth';
import Header from './header';

import { Container, Navigation, Content, BgLeaf } from './styled';

const SiderLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { signOut, handleIsPassword } = useAuth();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(isMobile);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const toggleCollapsedIsMobile = useCallback(() => {
    if (isMobile) {
      setCollapsed(!collapsed);
    }
  }, [collapsed]);

  const handleLogout = () => {
    signOut();
    dispatch(validateTermPlanRequest({ check: false }));
    dispatch(plansClearSelectedRequest());
    dispatch(subscriptionsClearRequest());
    dispatch(planResetRequest());
    dispatch(subscriptionConfirmationSuccess({ check: false }));
    handleIsPassword(false);
  };

  dispatch(subscriptionReceiptClear());

  return (
    <>
      <Header toggleCollapsed={toggleCollapsed} collapsed={collapsed} />
      <Container>
        <Navigation collapsed={collapsed}>
          <div className="menu">
            <ul>
              <li>
                <Link
                  to="/buy"
                  className={location.pathname === '/buy' ? 'active' : ''}
                  onClick={toggleCollapsedIsMobile}
                >
                  <span className="icon">
                    <img src="/static/icons/buy_now.svg" alt="Buy" />
                  </span>{' '}
                  {t('navigation.buynow')}
                </Link>
              </li>
              <li>
                <Link
                  to="/myplan"
                  className={location.pathname === '/myplan' ? 'active' : ''}
                  onClick={toggleCollapsedIsMobile}
                >
                  <span className="icon">
                    <img src="/static/icons/plan.svg" alt="Plan" />
                  </span>
                  {t('navigation.plans')}
                </Link>
              </li>
              <li>
                <Link
                  to="/schedule/create"
                  className={
                    location.pathname.match(/schedule/) ? 'active' : ''
                  }
                  onClick={toggleCollapsedIsMobile}
                >
                  <span className="icon">
                    <img src="/static/icons/schedule.svg" alt="Agendamento" />
                  </span>
                  {t('navigation.schedules')}
                </Link>
              </li>
              <li>
                <Link
                  to="/myunit"
                  className={location.pathname.match(/myunit/) ? 'active' : ''}
                  onClick={toggleCollapsedIsMobile}
                >
                  <MdStore size={24} />
                  {t('navigation.myunit')}
                </Link>
              </li>
              <li>
                <Link
                  to="/profile"
                  className={location.pathname === '/profile' ? 'active' : ''}
                  onClick={toggleCollapsedIsMobile}
                >
                  <span className="icon">
                    <img src="/static/icons/perfil.svg" alt="Perfil" />
                  </span>
                  {t('navigation.profile')}
                </Link>
              </li>
            </ul>

            <div className="logout">
              <button type="button" onClick={handleLogout}>
                <span className="icon">
                  <img src="/static/icons/logout.svg" alt="Logout" />
                </span>
                {t('navigation.exit')}
              </button>
            </div>
          </div>
        </Navigation>
        <Content>
          <div className="container">{children}</div>
        </Content>
      </Container>
      <BgLeaf>
        <img src="/static/images/bg-leaf.jpg" alt="Grupo SD Leaf" />
      </BgLeaf>
    </>
  );
};

export default SiderLayout;
