import React from 'react';

import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MoreOutlined,
} from '@ant-design/icons';

import { useAuth } from 'hooks/auth';

import { Header, GridTop } from './styled';

interface HeaderLayout {
  toggleCollapsed: any;
  collapsed: boolean;
}

const HeaderLayout: React.FC<HeaderLayout> = ({
  toggleCollapsed,
  collapsed,
}) => {
  const history = useHistory();
  const { userMe } = useAuth();

  return (
    <Header>
      <div className="toggleandbread">
        {!isMobile && (
          <div className="logo">
            <img src="/static/images/logo.svg" alt="Grupo SD" />
          </div>
        )}

        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: 'trigger',
            style: { color: '#667862', fontSize: 28 },
            onClick: () => {
              toggleCollapsed();
            },
          },
        )}
      </div>

      {isMobile && (
        <div className="logo-mobile">
          <img src="/static/logo.png" alt="Grupo SD" />
        </div>
      )}

      <GridTop>
        <div className="profile">
          <div>
            <p>{userMe.name}</p>
            <small>
              Unidade:{' '}
              <button type="button" onClick={() => history.push('/myunit')}>
                <strong>{userMe?.unit?.name}</strong>
              </button>
            </small>
          </div>

          <MoreOutlined
            style={{ fontSize: 26 }}
            onClick={() => history.push('/profile')}
          />
        </div>
      </GridTop>
    </Header>
  );
};

export default HeaderLayout;
