import { Reducer } from 'redux';
import { SubscriptionsState, SubscriptionsTypes, IContract } from './types';

const INITIAL_STATE: SubscriptionsState = {
  data: [],
  contract: {} as IContract,
  error: false,
  loading: true,
  loadingCreate: false,
  receipt: false,
  confirmation: false,
};

const reducer: Reducer<SubscriptionsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case SubscriptionsTypes.LOAD_SUBSCRIPTIONS_REQUEST:
      return { ...state, loading: true };
    case SubscriptionsTypes.LOAD_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case SubscriptionsTypes.LOAD_SUBSCRIPTIONS_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    case SubscriptionsTypes.CREATE_SUBSCRIPTIONS_REQUEST:
      return { ...state, loadingCreate: true, error: false };
    case SubscriptionsTypes.CLEAR_SUBSCRIPTIONS_REQUEST:
      return { ...state, data: [] };
    case SubscriptionsTypes.RECEIPT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        receipt: action.payload.data.check,
        loadingCreate: false,
      };
    case SubscriptionsTypes.RECEIPT_SUBSCRIPTIONS_CLEAR:
      return { ...state, receipt: false };
    case SubscriptionsTypes.CONFIRMATION_SUBSCRIPTIONS_SUCCESS:
      return { ...state, confirmation: action.payload.data.check };
    case SubscriptionsTypes.LOAD_CONTRACT_REQUEST:
      return { ...state };
    case SubscriptionsTypes.LOAD_CONTRACT_SUCCESS:
      return { ...state, contract: action.payload.data };
    default:
      return state;
  }
};

export default reducer;
