import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useAuth } from 'hooks/auth';

import Loading from 'components/Loading/spin';

import { plansUseSelector } from 'store/modules/plans/types';

import { subscriptionsLoadRequest } from 'store/modules/subscriptions/action';
import { subscriptionsUseSelector } from 'store/modules/subscriptions/types';

import { verifyStatusSubscriptions } from 'utils/verifyStatusSubscriptions';
import Step from './step';
import Choice from './choice';
import SubscriptionActive from './subscriptionActive';

import { ContainerLoading, Container, SignContent } from './styled';

const Buy: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedPlan } = useSelector(plansUseSelector);
  const { data: subscriptions, loading } = useSelector(
    subscriptionsUseSelector,
  );
  const { userMe } = useAuth();

  useEffect(() => {
    if (!subscriptions.length) {
      dispatch(subscriptionsLoadRequest());
    }
  }, [userMe]); //eslint-disable-line

  const verifyStatus = verifyStatusSubscriptions(subscriptions);

  if (loading) {
    return (
      <ContainerLoading>
        <Loading color="#999" fontSize={46} />
        <p>carregando...</p>
      </ContainerLoading>
    );
  }

  if (verifyStatus) {
    return (
      <Container>
        <SignContent>
          <SubscriptionActive />
        </SignContent>
      </Container>
    );
  }

  if (selectedPlan) {
    return <Step />;
  }

  return <Choice />;
};

export default Buy;
