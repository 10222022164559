import { useEffect, useState } from 'react';

export type Position = {
  latitude: number;
  longitude: number;
};

type UsePositionReturn = [Position, string];

const usePosition = (): UsePositionReturn => {
  const [position, setPosition] = useState<Position>({} as Position);
  const [error, setError] = useState<string>('');

  const onChange = ({ coords }: GeolocationPosition) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };

  const onError = (error: GeolocationPositionError) => {
    setError(error.message);
  };

  useEffect(() => {
    const { geolocation } = navigator;
    if (!geolocation) {
      setError('Não foi possível pegar a localização');
      return;
    }

    const watcher = geolocation.watchPosition(onChange, onError);

    // eslint-disable-next-line consistent-return
    return () => geolocation.clearWatch(watcher);
  }, []);

  return [position, error];
};

export { usePosition };
