import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { t } from 'ab18n';

import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/Loading/spin';

import { subscriptionsLoadRequest } from 'store/modules/subscriptions/action';
import { subscriptionsUseSelector } from 'store/modules/subscriptions/types';

import SignatureCard from './card';
import { Container, GridLoad } from './styled';

const Signatures: React.FC = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(subscriptionsUseSelector);
  const [expand, setExpand] = useState(0);

  useEffect(() => {
    dispatch(subscriptionsLoadRequest());
  }, []); //eslint-disable-line

  const handleExpand = (event: number) => {
    if (event === expand) {
      setExpand(0);
      return;
    }
    setExpand(event);
  };

  const mySignatures = data
    .filter(item => item.status !== 'canceled')
    .filter(item => item.status !== 'expired');
  const myHistorical = data
    .filter(item => item.status !== 'delayed')
    .filter(item => item.status !== 'success');

  return (
    <>
      <Helmet>
        <title>Meu Plano | Portal do Cliente</title>
      </Helmet>

      <Container>
        {data.length === 0 && loading ? (
          <GridLoad>
            <Loading color="#999" fontSize={48} />
            <p>{t('general.loading')}.</p>
          </GridLoad>
        ) : (
          <>
            {data.length > 0 && (
              <>
                <h3>{t('general.myPlan')}</h3>
                {mySignatures.map(signature => (
                  <SignatureCard
                    signature={signature}
                    key={signature.id}
                    expand={signature.id === expand}
                    handleExpand={handleExpand}
                  />
                ))}

                <h3 style={{ marginTop: 12 }}>{t('general.myHistorical')}</h3>
                {myHistorical.map(signature => (
                  <SignatureCard
                    signature={signature}
                    key={signature.id}
                    expand={signature.id === expand}
                    handleExpand={handleExpand}
                  />
                ))}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Signatures;
