import { all, takeLatest } from 'redux-saga/effects';

import { UnitsTypes } from './units/types';
import { loadUnits, linkUnit, showUnit, loadGateWay } from './units/sagas';

import { PlansTypes } from './plans/types';
import { loadPlans, selectPlans } from './plans/sagas';

import { SubscriptionsTypes } from './subscriptions/types';
import {
  loadSubscriptions,
  createSubscription,
  loadContracts,
} from './subscriptions/sagas';

export default function* rootSaga(): any {
  return yield all([
    takeLatest(UnitsTypes.LOAD_UNITS_REQUEST, loadUnits),
    takeLatest(UnitsTypes.SHOW_UNIT_REQUEST, showUnit),
    takeLatest(UnitsTypes.LINK_UNIT_REQUEST, linkUnit),
    takeLatest(UnitsTypes.LOAD_GATEWAY_REQUEST, loadGateWay),

    takeLatest(PlansTypes.LOAD_PLANS_REQUEST, loadPlans),
    takeLatest(PlansTypes.SELECT_PLANS_REQUEST, selectPlans),

    takeLatest(SubscriptionsTypes.LOAD_CONTRACT_REQUEST, loadContracts),
    takeLatest(
      SubscriptionsTypes.LOAD_SUBSCRIPTIONS_REQUEST,
      loadSubscriptions,
    ),
    takeLatest(
      SubscriptionsTypes.CREATE_SUBSCRIPTIONS_REQUEST,
      createSubscription,
    ),
  ]);
}
