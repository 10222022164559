import styled from 'styled-components';

export const Background = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 99;
  background: url('/static/images/bg-bubble.jpg') no-repeat right bottom;
  background-size: 68% auto;
  padding-bottom: 300px;

  @media only screen and (min-width: 1024px) {
    height: 100vh;
    padding-bottom: 0px;
    background-size: 36% auto;
  }

  @media only screen and (min-width: 1280px) {
    height: 100vh;
    padding-bottom: 0px;
    background-size: 36% auto;
  }

  @media only screen and (min-width: 1480px) {
    height: 100vh;
    padding-bottom: 0px;
    background-size: 36% auto;
  }
`;

export const Grid = styled.div`
  display: flex;
  position: relative;
  padding-left: 14px;
  padding-right: 14px;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 98%;
  transform: translateY(0);

  @media only screen and (min-width: 1280px) {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    padding-left: 0;
    padding-right: 0;
    width: 50%;
  }

  @media only screen and (min-width: 1480px) {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    padding-left: 0;
    padding-right: 0;
    width: 50%;
  }
`;

export const TextTop = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;

  h3 {
    font-size: 26px;
    font-weight: bold;
    color: #666666;
  }

  p {
    font-size: 20px;
    line-height: 22px;
    color: #666666;
  }

  @media only screen and (min-width: 1280px) {
    margin-top: 0px;

    h3 {
      font-size: 32px;
    }

    p {
      font-size: 26px;
      line-height: 28px;
    }
  }

  @media only screen and (min-width: 1480px) {
    margin-top: 0px;

    h3 {
      font-size: 32px;
    }

    p {
      font-size: 26px;
      line-height: 28px;
    }
  }
`;
