import { call, put } from 'redux-saga/effects';

import api from 'services/api';
import LOCALSTORAGEKEY from 'constants/localStorage';

import message from 'components/Message';

import { plansLoadSuccess, plansLoadFailure } from './action';
import { IPlans } from './types';

export function* loadPlans({ payload }: any): any {
  try {
    const { is_first_attandance } = payload;

    // const { data } = yield call(
    //   api.get,
    //   `plans?isFirstAttendance=${is_first_attandance}`,
    // );
    const { data } = yield call(api.get, `plans`);

    const plans = data.data.filter(
      (item: any) => item.isFirstAttendance === is_first_attandance,
    );

    const planLocalStorage = localStorage.getItem(LOCALSTORAGEKEY.PLAN);

    if (planLocalStorage && planLocalStorage !== 'undefined') {
      const parsePlan = JSON.parse(planLocalStorage);
      const findPlan = plans.find((item: IPlans) => item.id === parsePlan.id);
      localStorage.setItem(LOCALSTORAGEKEY.PLAN, JSON.stringify(findPlan));
    }

    yield put(plansLoadSuccess(plans));
  } catch (err: any) {
    yield put(plansLoadFailure());
    message.responseErrors(err);
  }
}

export function selectPlans({ payload }: any): any {
  const { data } = payload;

  localStorage.setItem(LOCALSTORAGEKEY.PLAN, JSON.stringify(data));
}
