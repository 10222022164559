import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';

import { useHistory } from 'react-router-dom';

import api from 'services/api';

import message from 'components/Message';

import { useAuth } from 'hooks/auth';

import { nameCase } from 'utils/format';
import { stringNumber } from 'utils/mask';
import { IRegister } from './ITypes';

import RegisterForm from './form';
import ValidationCode from './code';
import EmailOrSms from './emailOrSms';

import { Background } from './styles';

const Register: React.FC = () => {
  const {
    identifier,
    handleIdentifier,
    handleRegisterOrPassword,
    loading,
    handleVerifyIdentifier,
  } = useAuth();

  const history = useHistory();

  const handleSubmit = async (value: IRegister) => {
    if (!identifier.newUser) {
      handleIdentifier({
        ...identifier,
        emailOrSms: 'null',
        password: value.password,
      });
      return;
    }

    await handleRegisterOrPassword({
      name: nameCase(value.name),
      email: String(value.email),
      password: String(value.password),
      identifier: stringNumber(value.identifier),
      phone: stringNumber(String(value.phone)),
      type: 'register',
    });
  };

  const handleSendCode = useCallback(
    async (event: string) => {
      try {
        message.loading();
        await api.post(`verify/confirmation/${identifier.id}`, {
          method: event,
        });

        handleIdentifier({
          ...identifier,
          emailOrSms: event === 'email' ? 'email' : 'sms',
          validationCode: true,
        });

        message.success(`Código enviado por ${event}`, 5);
      } catch (err: any) {
        message.responseErrors(err);
      }
    },
    [handleIdentifier, identifier],
  );

  const handleChangeVerifyIsIdentify = async (event: any) => {
    const fiscalNumber = stringNumber(event.target.value);

    if (fiscalNumber.length === 11) {
      message.loading();
      await handleVerifyIdentifier(fiscalNumber);
    }
  };

  const handleRegisterPassword = async (event: any) => {
    await handleRegisterOrPassword({
      id: String(identifier.id),
      identifier: identifier.identifier,
      password: String(identifier.password),
      code: event.code,
      type: 'registerPassword',
    });
  };

  return (
    <>
      <Helmet>
        <title>Cadastrar | Portal do Cliente</title>
      </Helmet>

      <Background>
        {identifier.emailOrSms && !identifier.validationCode && (
          <EmailOrSms handleSendCode={handleSendCode} />
        )}
        {identifier.validationCode && identifier.emailOrSms && (
          <ValidationCode
            handleValidationCode={handleRegisterPassword}
            loading={loading}
          />
        )}
        {!identifier.validationCode && !identifier.emailOrSms && (
          <RegisterForm
            handleSubmit={handleSubmit}
            handleChangeVerifyIsIdentify={handleChangeVerifyIsIdentify}
            history={history}
            loading={loading}
          />
        )}
      </Background>
    </>
  );
};

export default Register;
