import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import { t } from 'ab18n';

import { useHistory } from 'react-router-dom';
import LOCALSTORAGEKEY from 'constants/localStorage';
import moment from 'moment';

import jwtDecode from 'jwt-decode';

import { nameCase } from 'utils/format';
import { stringNumber, cepUnMask, phoneUnMask } from 'utils/mask';

import message from 'components/Message';

import api from 'services/api';

import {
  IUser,
  IIdentifier,
  IRegisterUser,
  AuthState,
  AuthContextData,
} from './iTypes';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(LOCALSTORAGEKEY.TOKEN);

    if (token) {
      return { token, logged: true };
    }
    return { token: '', logged: false } as AuthState;
  });
  const [user, setUser] = useState<IUser>(() => {
    const token = localStorage.getItem(LOCALSTORAGEKEY.TOKEN);

    if (token) {
      const { user }: any = jwtDecode(token);

      return user;
    }
    return {} as IUser;
  });
  const [me, setMe] = useState<IUser>({} as IUser);
  const [ifExistsAddress, setIfExistsAddress] = useState<boolean>(() => {
    const getAddress = localStorage.getItem(LOCALSTORAGEKEY.ADDRESS_EXIST);

    if (getAddress) {
      return true;
    }

    return false;
  });
  const [identifier, setIdentifier] = useState<IIdentifier>({} as IIdentifier);
  const [userUnit, setUserUnit] = useState<string>(() => {
    const unit_id = localStorage.getItem(LOCALSTORAGEKEY.UNIT_ID);

    if (unit_id) {
      return unit_id;
    }

    return 'null';
  });
  const [isPassword, setIsPassword] = useState(false);

  useEffect(() => {
    async function loadUser() {
      try {
        const { data } = await api.get('auth/me');

        if (!data.data.unit_id) {
          setUserUnit('null');
        }

        const userData = data.data;

        setMe(userData);
      } catch (err: any) {
        if (err.response?.status === 401 && data.logged) {
          localStorage.removeItem(LOCALSTORAGEKEY.TOKEN);
          localStorage.removeItem(LOCALSTORAGEKEY.UNIT_ID);
          localStorage.removeItem(LOCALSTORAGEKEY.PLAN);

          setData({ token: '', logged: false } as AuthState);
        }
      }
    }

    loadUser();
  }, [data.logged]);

  const authMe = async () => {
    try {
      const { data } = await api.get('auth/me');

      const userData = data.data;

      if (userData?.address) {
        localStorage.setItem(LOCALSTORAGEKEY.ADDRESS_EXIST, 'true');
        setIfExistsAddress(true);
      }
    } catch (err: any) {
      console.log('authMe', err);
    }
  };

  const signIn = useCallback(
    async ({ identifier, password }) => {
      try {
        const response = await api.post('auth/login', {
          identifier,
          password,
        });

        const { access_token } = response.data.data;

        const { user }: any = jwtDecode(access_token);

        localStorage.setItem(LOCALSTORAGEKEY.TOKEN, access_token);
        localStorage.setItem(
          LOCALSTORAGEKEY.UNIT_ID,
          user.unit_id ? String(user.unit_id) : '',
        );

        api.defaults.headers.common.Authorization = `Bearer ${access_token}`;

        await authMe();

        setUser(user);
        setData({ token: access_token, logged: true });

        if (user.unit_id) {
          setUserUnit(String(user.unit_id));
          history.push('/buy');
        } else {
          message.warning('Necessário ser vinculado a uma unidade.');
          history.push('/');
        }
      } catch (err: any) {
        message.responseErrors(err);
      }
    },
    [history],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem(LOCALSTORAGEKEY.TOKEN);
    localStorage.removeItem(LOCALSTORAGEKEY.UNIT_ID);
    localStorage.removeItem(LOCALSTORAGEKEY.PLAN);
    localStorage.removeItem(LOCALSTORAGEKEY.ADDRESS_EXIST);

    setData({ token: '', logged: false } as AuthState);
  }, []);

  const handleRegisterOrPassword = useCallback(
    async ({
      id,
      code,
      name,
      email,
      password,
      identifier = '',
      phone,
      type,
    }: IRegisterUser) => {
      try {
        message.loading();

        if (type === 'register') {
          await api.post('auth/register', {
            name: nameCase(name),
            email,
            password,
            identifier: stringNumber(identifier),
            phone: stringNumber(String(phone)),
          });
        } else {
          await api.post(`auth/password/${id}`, {
            password,
            password_confirmation: password,
            code,
          });
        }

        message.success('Cadastro realizado com sucesso');

        await signIn({
          password: String(password),
          identifier: stringNumber(identifier),
        });
      } catch (err: any) {
        message.responseErrors(err);
      }
    },
    [], // eslint-disable-line
  );

  const handleRemoveUserUnit = useCallback(() => {
    localStorage.removeItem(LOCALSTORAGEKEY.UNIT_ID);
    setUserUnit('null');
    history.push('/');
  }, [history]);

  const handleIdentifier = useCallback((data: IIdentifier) => {
    setIdentifier(data);
  }, []);

  const handleLoading = useCallback((event: boolean) => {
    setLoading(event);
  }, []);

  const handleUpdateUser = useCallback(
    (event: IUser) => {
      const updateUser = {
        ...event,
        isFirstServiceAtSD: me.isFirstServiceAtSD,
        isFirstServiceAtUnit: me.isFirstServiceAtUnit,
      };
      setUser(updateUser);
      setMe(updateUser);
    },
    [me],
  );

  const handleIsPassword = useCallback((event: boolean) => {
    setIsPassword(event);
  }, []);

  const handleVerifyIdentifier = useCallback(
    async (event: string) => {
      try {
        const { data } = await api.get(`verify/identifier/${event}`);

        if (data.data.hasPassword) {
          setIsPassword(true);
          setIdentifier({
            ...identifier,
            identifier: event,
            name: data.data.name,
          });
          history.push('/login');
        } else {
          setIdentifier({
            ...data.data,
            newUser: false,
          });
          history.push('/register');
        }
        message.success('Usuário Identificado', 5);
      } catch (err: any) {
        console.log(err.response);
        if (err.response.data?.message) {
          message.warning(err.response.data.message, 5);
          history.push('/register');
          setIdentifier({
            newUser: true,
            identifier: event,
          } as IIdentifier);
        } else {
          message.error(err.response.statusText, 5);
        }
      }
    },
    [history, identifier],
  );

  const handleUpdateAddress = useCallback(
    async (value: any) => {
      try {
        const { user, address } = value;

        setLoading(true);
        message.loading();

        const response = await api.put('user/link', {
          user: {
            ...user,
            phone: phoneUnMask(user?.phone),
            birthdate: moment(user?.birthdate, 'YYYY-MM-DD').format(
              'YYYY-MM-DD',
            ),
          },
          address: {
            ...address,
            postal_code: cepUnMask(address?.postal_code),
          },
        });

        setLoading(false);

        handleUpdateUser(response.data.data);
        localStorage.setItem(LOCALSTORAGEKEY.ADDRESS_EXIST, 'true');
        setIfExistsAddress(true);
        message.success(t('messages.success'), 5);
      } catch (err: any) {
        if (err.response.data.message) {
          message.warning(err.response.data.message, 5);
        } else {
          message.error(err.response.statusText, 5);
        }
      }
    },
    [handleUpdateUser],
  );

  return (
    <AuthContext.Provider
      value={{
        loading,
        logged: data.logged,
        identifier,
        user,
        userMe: me,
        ifExistsAddress,
        userUnit,
        isPassword,
        signIn,
        signOut,
        handleLoading,
        handleIdentifier,
        handleUpdateUser,
        handleRegisterOrPassword,
        handleRemoveUserUnit,
        handleIsPassword,
        handleVerifyIdentifier,
        handleUpdateAddress,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(' useAuth must be used within an authProvider ');
  }
  return context;
}
export { AuthProvider, useAuth };
