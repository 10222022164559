import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';

import { t } from 'ab18n';

import api from 'services/api';

import { isMobile } from 'react-device-detect';

import { Form } from 'antd';

import { useAuth } from 'hooks/auth';

import message from 'components/Message';

import { stringNumber } from 'utils/mask';
import Desktop from './isDevice/desktop';
import Mobile from './isDevice/mobile';

const Login: React.FC = () => {
  const {
    signIn,
    identifier,
    isPassword,
    handleVerifyIdentifier,
    handleIdentifier,
  } = useAuth();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState('');

  const handleLogin = useCallback(
    async (event: any) => {
      setLoading(true);

      if (!isPassword) {
        await handleVerifyIdentifier(stringNumber(event.identifier));
      } else {
        message.loading();

        await signIn({
          password: event.password,
          identifier: identifier.identifier,
        });
      }

      setLoading(false);
    },
    [isPassword, signIn, identifier.identifier, handleVerifyIdentifier],
  );

  const handleSendCode = useCallback(
    async (event: any) => {
      try {
        message.loading();
        setLoadingReset(true);
        await api.post(`auth/password/reset/${identifier.identifier}`, {
          send: event,
        });

        handleIdentifier({
          ...identifier,
          emailOrSms: event === 'email' ? 'email' : 'sms',
          validationCode: true,
        });

        message.success(`${t('general.codeSend')} ${event}`);

        setLoadingReset(false);
        setIsPasswordReset('code');
      } catch (err: any) {
        setLoadingReset(false);
        message.responseErrors(err);
      }
    },
    [handleIdentifier, identifier],
  );

  const handleIsPasswordReset = useCallback((event: any) => {
    setIsPasswordReset(event);
  }, []);

  const handleLoginResetPassword = useCallback(
    async (data: any) => {
      try {
        setLoadingReset(true);
        await api.post(
          `auth/password/changePassword/${identifier.identifier}`,
          data,
        );

        await signIn({
          identifier: identifier.identifier,
          password: data.password,
        });
      } catch (err: any) {
        setLoadingReset(false);
        message.responseErrors(err);
      }
    },
    [identifier, signIn],
  );

  return (
    <>
      <Helmet>
        <title>Acesso | Portal do Cliente</title>
      </Helmet>
      {isMobile ? (
        <Mobile
          handleLogin={handleLogin}
          loading={loading}
          form={form}
          nameUser={identifier.name}
          handleSendCode={handleSendCode}
          isPasswordReset={isPasswordReset}
          handleIsPasswordReset={handleIsPasswordReset}
          handleLoginResetPassword={handleLoginResetPassword}
          loadingReset={loadingReset}
        />
      ) : (
        <Desktop
          handleLogin={handleLogin}
          loading={loading}
          form={form}
          nameUser={identifier.name}
          handleSendCode={handleSendCode}
          isPasswordReset={isPasswordReset}
          handleIsPasswordReset={handleIsPasswordReset}
          handleLoginResetPassword={handleLoginResetPassword}
          loadingReset={loadingReset}
        />
      )}
    </>
  );
};

export default Login;
