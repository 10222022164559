import React from 'react';

import { MdExpandMore, MdExpandLess } from 'react-icons/md';

import { t } from 'ab18n';

import { ISubscriptions } from 'store/modules/subscriptions/types';

import { dateFormatSimple, valueToCurrency } from 'utils/format';

import { cnpjMask } from 'utils/mask';
import { Card, Expand } from './styled';

interface ISignature {
  signature: ISubscriptions;
  expand: boolean;
  handleExpand(data: number): void;
}

const SignatureCard: React.FC<ISignature> = ({
  signature,
  expand,
  handleExpand,
}) => {
  // retirando os serviços da assinaturas
  const servicesMap = signature.customerService.map(item => item.service);
  const uniqueServices = servicesMap.filter((item, pos) => {
    return servicesMap.indexOf(item) === pos;
  });

  let backgroundColor = '#424242';

  if (signature.status === 'success') {
    backgroundColor = '#0aa77a';
  }

  if (signature.status === 'delayed') {
    backgroundColor = '#ca9810';
  }

  if (signature.status === 'expired') {
    backgroundColor = '#c42e4f';
  }

  if (signature.status === 'canceled') {
    backgroundColor = '#c42e4f';
  }

  return (
    <Card>
      <div className="top-head">
        <div className="status" style={{ backgroundColor }}>
          {t(`pages.signatures.status.${signature.status}`)}
        </div>
        <span>
          <h2>{signature.plan.name}</h2>
        </span>
        <span>
          <p>{uniqueServices.join(' + ')}</p>
        </span>
        <span>
          <p>
            <strong>Unidade:</strong> {signature.unit?.name}
            <br />
            <strong>CNPJ:</strong> {cnpjMask(signature.unit?.cnpj)}
          </p>
        </span>
      </div>
      <div className="top">
        <span className="due-date">
          <span>
            {t('pages.signatures.start')}:{' '}
            {dateFormatSimple(signature.createdAt)}
          </span>
          <span>
            {t('pages.signatures.dueDate')}:{' '}
            {dateFormatSimple(signature.dueDate)}
          </span>
        </span>
        <span className="btn-status">
          <span>
            <button
              type="button"
              className="more-details green-dark"
              onClick={() => handleExpand(signature.id)}
            >
              {expand ? (
                <>
                  {t('pages.signatures.lessDetails')} <MdExpandMore />
                </>
              ) : (
                <>
                  {t('pages.signatures.moreDetails')} <MdExpandLess />
                </>
              )}
            </button>
          </span>
        </span>
      </div>
      <div className="top">
        <span className="green-p">
          {t('pages.signatures.proceedingPayment')}:{' '}
          {
            signature.customerService.filter(item => item.status !== 'enabled')
              .length
          }{' '}
          de {signature.customerService.length}
        </span>
      </div>
      <Expand expand={expand}>
        <div className="table">
          {signature.customerService.filter(item => item.when !== null).length >
          0 ? (
            <table>
              <thead>
                <tr>
                  <td width="88%">{t('pages.signatures.dateProceeding')}</td>
                  <td align="right">status</td>
                </tr>
              </thead>
              <tbody>
                {signature.customerService.map(item => (
                  <React.Fragment key={item.id}>
                    {item.status !== 'enabled' && (
                      <tr>
                        <td>
                          <span className="flex">
                            {dateFormatSimple(item.when)} <hr />
                          </span>
                        </td>
                        <td
                          className={
                            item.status === 'canceled' ? 'red' : 'green'
                          }
                          align="right"
                        >
                          {t(`pages.signatures.services.${item.status}`)}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <td width="88%">{t('pages.signatures.dateProceeding')}</td>
                    <td align="right">status</td>
                  </tr>
                </thead>
              </table>
              <div className="empty">{t('pages.signatures.none')}.</div>
            </>
          )}
        </div>

        {signature.revenue.length > 0 && (
          <div className="table">
            <p>{t('pages.signatures.detailsPayment')}:</p>

            <table>
              <thead>
                <tr>
                  <td>{t('pages.signatures.dueDate')}</td>
                  <td>{t('pages.signatures.paidOut')}</td>
                  <td>{t('pages.signatures.value')}</td>
                  <td align="right">{t('pages.signatures.statusTxt')}</td>
                </tr>
              </thead>
              <tbody>
                {signature.revenue.map(item => (
                  <tr key={item.id}>
                    <td>
                      <span className="flex">
                        <span>{dateFormatSimple(item.scheduledDate)}</span>{' '}
                        <hr />
                      </span>
                    </td>
                    <td>
                      <span className="flex">
                        <span>{dateFormatSimple(item.paymentDate)}</span> <hr />
                      </span>
                    </td>
                    <td>
                      <span className="flex">
                        <span>{valueToCurrency(item.amount)}</span> <hr />
                      </span>
                    </td>
                    <td
                      width="10%"
                      align="right"
                      className={
                        item.status !== 'scheduled' ? 'orange' : 'green'
                      }
                    >
                      {t(`pages.signatures.revenue.${item.status}`)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Expand>
    </Card>
  );
};

export default SignatureCard;
