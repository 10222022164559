import React, { useState, useEffect, useCallback } from 'react';

import { Modal, Result } from 'antd';
import api from 'services/api';

import { useDispatch, useSelector } from 'react-redux';

import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

import { MdLabel } from 'react-icons/md';
import { format } from 'date-fns';

import { Zoom } from 'react-awesome-reveal';

import { dateFormat } from 'utils/format';

import { unitsShowRequest } from 'store/modules/units/action';
import { unitsUseSelector } from 'store/modules/units/types';

import Calendar from 'components/Calendar';
import Button from 'components/Button/custom';
import Loading from 'components/Loading/spin';
import message from 'components/Message';

import { useCalendar } from 'hooks/calendar';
import { useAuth } from 'hooks/auth';
import { hours } from 'utils/calendar';

import {
  Container,
  Schedule,
  Grid,
  ButtonsContainer,
  ContainerModal,
} from './styles';

interface IUnitsHours {
  hour: string;
  hournumber: number;
  total: number;
  percentage: number;
  color: string;
}

const Create: React.FC = () => {
  const { getDate } = useCalendar();
  const { userMe } = useAuth();
  const dispatch = useDispatch();
  const { unit } = useSelector(unitsUseSelector);

  const [indexHour, setIndexHour] = useState<any>('');
  const [unitsHours, setUnitsHours] = useState<IUnitsHours[]>(
    [] as IUnitsHours[],
  );
  const [loading, setLoading] = useState(true);
  const [loadSend, setLoadSend] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [schedule, setSchedule] = useState<any>({});

  useEffect(() => {
    const formatDate = format(getDate, 'yyyy-MM-dd');

    setUnitsHours([]);

    if (!Object.keys(userMe).length) {
      return;
    }

    dispatch(unitsShowRequest({ email: userMe.unit.email }));

    async function loadVisistsHours() {
      try {
        setLoading(true);

        const response = await api.get(`visits-by-hour`, {
          params: {
            date: formatDate,
          },
        });

        const mapHours = response.data.data.map((item: any) => {
          let color = '';
          if (item.percentage < 0.5) {
            color = 'green';
          } else if (item.percentage === 1) {
            color = 'red';
          } else {
            color = 'orange';
          }
          return {
            ...item,
            hournumber: Number(item.hour.substr(0, 2)),
            color,
          };
        });

        setUnitsHours(mapHours);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    }

    loadVisistsHours();
  }, [getDate, userMe]); //eslint-disable-line

  const handleHour = useCallback((event: number) => {
    setIndexHour(String(event));
  }, []);

  const handleSchedule = useCallback(async () => {
    try {
      setLoadSend(true);
      const unitHour = unitsHours[Number(indexHour)];

      const dateFormat = format(getDate, 'yyyy-MM-dd');

      const { data } = await api.post('send-schedule', {
        when: `${dateFormat} ${unitHour.hour}`,
      });

      setLoadSend(false);

      if (!data.errors?.length) {
        setSchedule(data);
      }

      setOpenModal(true);
    } catch (err: any) {
      setLoadSend(false);
      message.responseErrors(err);
    }
  }, [unitsHours, indexHour, getDate]);

  return (
    <>
      <Zoom>
        <Container>
          <h3>Agende seu atendimento</h3>
          <Grid>
            <h4>Escolha o Dia</h4>
            {/* <div className="container-action">
            <Button
              type="button"
              size="small"
              onClick={() => history.push('/schedules')}
            >
              Voltar
            </Button>
          </div> */}
          </Grid>

          <Schedule>
            <Calendar />
          </Schedule>

          <Grid style={{ marginTop: 12 }}>
            <div className="grid">
              <h4>Escolha o Horário</h4>
              <div className="legend-hour">
                <div>Fluxo de pessoas: </div>
                <div>
                  <MdLabel color="#009933" /> Baixo
                </div>
                <div>
                  <MdLabel color="#ff8c1a" /> Médio
                </div>
                <div>
                  <MdLabel color="#ff4d4d" /> Alto
                </div>
              </div>
            </div>
          </Grid>

          {loading ? (
            <div className="loading">
              <Loading color="#999" fontSize={44} />
            </div>
          ) : (
            <>
              {unitsHours.length > 0 ? (
                <ButtonsContainer>
                  {hours({
                    date: getDate,
                    hours: unitsHours[0].hournumber,
                    minutes: 30,
                    sumQtd: unitsHours.length,
                  }).map((item: any, index) => {
                    return (
                      <button
                        key={item.hours}
                        type="button"
                        className={`${unitsHours[index].color} ${
                          indexHour &&
                          index === Number(indexHour) &&
                          `active-${unitsHours[index].color}`
                        }`}
                        onClick={() => handleHour(index)}
                        disabled={item.isPast}
                      >
                        {item.hours}
                        {!item.isPast && (
                          <span className={`line-${unitsHours[index].color}`} />
                        )}
                      </button>
                    );
                  })}
                </ButtonsContainer>
              ) : (
                <div className="alert">Loja Fechada</div>
              )}
            </>
          )}

          <Button
            type="button"
            disabled={!indexHour}
            style={{ marginTop: 12 }}
            onClick={handleSchedule}
            loading={loadSend}
          >
            Agendar
          </Button>
        </Container>
      </Zoom>

      <Modal
        centered
        visible={openModal}
        onCancel={() => {
          setOpenModal(false);
          setSchedule({});
        }}
        footer={
          Object.keys(schedule).length === 0
            ? [
                <Button
                  type="button"
                  onClick={() => {
                    setOpenModal(false);
                    setSchedule({});
                  }}
                >
                  OK
                </Button>,
              ]
            : []
        }
        width={1000}
      >
        <ContainerModal>
          {Object.keys(schedule).length > 0 ? (
            <Result
              status="success"
              title="Obrigada por seu agendamento!"
              subTitle="A unidade entrará em contato com você."
            >
              <p>
                Unidade: {unit.name} - Nome: {userMe.name}
              </p>
              <p>Agendamento: {dateFormat(schedule.when)}</p>
              <h3>Nos siga nas nossas redes sociais.</h3>
              <div className="icone">
                <a
                  href="https://www.facebook.com/sobrancelhasdesign"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookSquare />
                </a>
                <a
                  href="https://www.instagram.com/sobrancelhasdesign"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
              </div>
            </Result>
          ) : (
            <Result
              status="warning"
              title="Já existe uma solicitação de agendamento nesse dia"
              subTitle="Por favor! Escolha outro dia para o agendamento."
            />
          )}
        </ContainerModal>
      </Modal>
    </>
  );
};

export default Create;
