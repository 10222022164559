import styled from 'styled-components';
import { PageHeader } from 'antd';

export const ShowUnit = styled(PageHeader)`
  background: #eee;
  box-shadow: 3px 3px 5px 0px rgb(154 160 185 / 91%),
    -1px -1px 6px rgb(166 173 201 / 52%);
  text-align: left;
`;

export const FormUnit = styled.div`
  display: flex;
  flex-direction: column;
  text-align: -webkit-center;

  select + select {
    margin-top: 12px;
  }

  .grid {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    button + button {
      margin-left: 8px;
    }
  }
`;

export const DescriptionUnit = styled.span`
  font-weight: 700;
  margin-right: 5px;
`;

export const LoadingGrid = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p {
    padding-left: 6px;
    margin: 0px;
  }
`;
