import styled, { css } from 'styled-components';

interface IGrid {
  columns?: number;
}

export const Container = styled.div`
  display: block;
  width: 100%;
  margin: 0px auto;

  @media only screen and (min-width: 1024px) {
    width: 100%;
  }

  @media only screen and (min-width: 1280px) {
    width: 90%;
  }

  @media only screen and (min-width: 1440px) {
    width: 100%;
  }

  .container-grid {
    display: flex;
    flex-direction: column;
    width: 100%;

    .grid {
      width: 100%;
    }
  }

  .grid-form-radio {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;

    .form-radio {
      display: flex;

      label {
        margin-left: 6px;
      }
    }
  }

  @media only screen and (min-width: 481px) {
    .container-grid {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  @media only screen and (min-width: 1024px) {
    .container-grid {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  @media only screen and (min-width: 1280px) {
    .container-grid {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }
`;

export const Grid = styled.div<IGrid>`
  display: grid;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;

  ${props =>
    props.columns === 2
      ? css`
          grid-template-columns: repeat(1, 1fr);
          gap: 0px;

          @media only screen and (min-width: 1024px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
          }

          @media only screen and (min-width: 1280px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
          }

          @media only screen and (min-width: 1480px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
          }
        `
      : css`
          grid-template-columns: repeat(1, 1fr);
          gap: 0px;
        `}

  input {
    width: 100%;
  }
`;
