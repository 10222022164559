import { call, put } from 'redux-saga/effects';

import api from 'services/api';
import LOCALSTORAGEKEY from 'constants/localStorage';

import message from 'components/Message';

import {
  unitLinkLoad,
  unitsLoadSuccess,
  unitsShowSuccess,
  unitsLoadFailure,
  gatewayLoadSuccess,
} from './action';

export function* loadUnits(): any {
  try {
    message.loading();
    const response = yield call(api.get, 'units');

    yield put(unitsLoadSuccess(response.data.data));
  } catch (err: any) {
    yield put(unitsLoadFailure());
    message.responseErrors(err);
  }
}

export function* showUnit({ payload }: any): any {
  try {
    const response = yield call(api.get, `units/${payload.data.email}`);

    yield put(unitsShowSuccess(response.data.data));
  } catch (err: any) {
    message.responseErrors(err);
  }
}

export function* linkUnit({ payload }: any): any {
  try {
    yield call(api.post, `user/unit/${payload.data.email}`);
    yield put(unitLinkLoad());

    localStorage.setItem(LOCALSTORAGEKEY.UNIT_ID, String(payload.data.id));
    window.location.reload();
  } catch (err: any) {
    message.responseErrors(err);

    yield put(unitLinkLoad());
  }
}

export function* loadGateWay({ payload }: any): any {
  try {
    const response = yield call(api.get, `gateway-user/${payload.data.email}`);
    yield put(gatewayLoadSuccess(response.data.data));
  } catch (err: any) {
    message.responseErrors(err);
  }
}
