export const description = () => {
  const text = `<h4>O que você encontrará acessando nosso sistema por aqui:</h4>
  <ul>
    <li>Descontos Exclusivos</li>
    <li>Unidade Favorita</li>
    <li>
    Status da Assinatura
    </li>
  </ul>`;
  return text;
};
