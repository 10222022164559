import React from 'react';

import CalendarHeader from './CalendarHeader';
import CalendarDays from './CalendarDays';
import CalendarCells from './CalendarCells';
import CalendarFooter from './CalendarFooter';

import { Container } from './styles';

const Calendar: React.FC = () => {
  return (
    <Container>
      <CalendarHeader />
      <CalendarDays />
      <CalendarCells />
      <CalendarFooter />
    </Container>
  );
};

export default Calendar;
