import VMasker from 'vanilla-masker';

export const cpfMask = (value: any) =>
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '999.999.999-99')
    : '';

export const cnpjMask = (value: any) =>
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '99.999.999/9999-99')
    : '';

export const stringNumber = (value: string) => value.replace(/[^\d]+/g, '');

export const phoneMask = (value: any) =>
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '(99) 99999-9999')
    : '';

export const phoneUnMask = (value: any) =>
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '99999999999')
    : '';

export const phoneSecret = (value: any) =>
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '(99) *****-9999')
    : '';

export const codeValidation = (value: number) =>
  value && VMasker.toNumber(value) ? VMasker.toPattern(value, '9999') : '';

export const cepMask = (value: any) =>
  value && VMasker.toNumber(value) ? VMasker.toPattern(value, '99999-999') : '';

export const cepUnMask = (value: any) =>
  value && VMasker.toNumber(value) ? VMasker.toPattern(value, '99999999') : '';

export const py = (value: any) =>
  value && VMasker.toNumber(value) ? VMasker.toPattern(value, '9.999.999') : '';

export const creditCardMask = (value: any) =>
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '9999 9999 9999 9999')
    : '';

export const creditCardUnMask = (value: any) =>
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '9999999999999999')
    : '';

export const cardCvcMask = (value: any) =>
  value && VMasker.toNumber(value) ? VMasker.toPattern(value, '999') : '';

export const codeRegister = (value: any) =>
  value && VMasker.toNumber(value) ? VMasker.toPattern(value, '9999') : '';

export const expiryDateMask = (value: any) =>
  value && VMasker.toNumber(value) ? VMasker.toPattern(value, '99/99') : '';
