import React from 'react';

import { DoubleLeftOutlined } from '@ant-design/icons';

import { useAuth } from 'hooks/auth';

import Button from 'components/Button/custom';

import { GridForm } from './styles';

interface IEmailOrSms {
  handleSendCode: any;
}

const EmailOrSms: React.FC<IEmailOrSms> = ({ handleSendCode }) => {
  const { identifier, handleIdentifier } = useAuth();

  return (
    <GridForm>
      <h3 className="text">Identificação de usuário</h3>

      <Button
        type="button"
        onClick={() => handleSendCode('email')}
        className="btn-send-email"
      >
        Enviar código via email
      </Button>
      <p className="text-or">ou</p>
      <Button
        type="button"
        onClick={() => handleSendCode('sms')}
        className="btn-send-sms"
      >
        Enviar código via sms
      </Button>

      <Button
        type="button"
        color="secondary"
        style={{ marginTop: 16 }}
        onClick={() => handleIdentifier({ ...identifier, emailOrSms: '' })}
      >
        <DoubleLeftOutlined /> Voltar
      </Button>
    </GridForm>
  );
};

export default EmailOrSms;
