import React from 'react';

import { useHistory } from 'react-router-dom';

import Success from './steps/success';
import { Card } from './styled';

export const ThankYou: React.FC = () => {
  const history = useHistory();

  const { state } = history.location;

  if (!state?.payment) {
    history.push('/buy');
  }

  return (
    <Card>
      <div className="card">
        <Success />
      </div>
    </Card>
  );
};
