import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import Button from 'components/Button/custom';

import { Card } from './styled';

const SubscriptionActive: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('?page=success');
  }, []) //eslint-disable-line

  return (
    <Card>
      <div className="subscription">
        <div className="content-subscription">
          <div className="icon-subscription">
            <img src="/static/icons/presente.svg" alt="SD ICON" />
          </div>
          <div>
            <div className="title">Parabéns por se tornar uma SD LOVER!!</div>
            <div className="subtitle">Olá o seu tratamento começa agora!</div>
          </div>
          <Button
            type="button"
            onClick={() => history.push('/schedule/create')}
          >
            Agendar Agora
          </Button>
        </div>

        <div className="img-subscription">
          <img src="/static/images/subscription-active.png" alt="SD" />
        </div>
      </div>
    </Card>
  );
};

export default SubscriptionActive;
